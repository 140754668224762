import { Controller } from "stimulus";
let modal;
let error;
let user_of_polling;


function buttonContainer(){
  return modal.querySelector('.button-container')
}

function hideModal() {
  modal.classList.remove("d-block");
  buttonContainer().innerHTML = "";
  error.classList.remove("d-block");
}

function showModal() {
  modal.classList.add("d-block");
}

function updateModalVisibility() {
  const length = buttonContainer().getElementsByTagName("a").length;
  if (length > 0) showModal();
  else hideModal();
}

function createButton(id, name, url, user_id,resource_id){
  if(user_id==user_of_polling){
  const element = document.getElementById(`cv-download-${name}`);
  const spinnerCvGenerate = document.getElementById(`spinnerCvGenerate${id}`);
  const downloadbuttonCvGenerate = document.getElementById(`downloadbuttonCvGenerate${id}`);
  if (element) { element.remove(); }
  if (spinnerCvGenerate) { spinnerCvGenerate.remove(); downloadbuttonCvGenerate.style.display = 'block';}
  removeErrorDownloadButtons(resource_id,id);
  return `<a class="uc-btn btn-secondary mr-20" id="cv-download-${name}" href="${url}" target="_blank" data-action="click->poll#removeButton" data-poll-id="${id}" >
        ${name}
        <i class="uc-icon ml-20">download</i>
    </a>`
  }
}

function changeStatusForButtons(id, status) {
  return fetch(`/api/downloads/${id}/change_status`, {
    method: 'PUT',
    headers: { "Content-type": "application/json; charset=UTF-8" },
    body: JSON.stringify({ download: { status } })
  }).then(response => response.json());
}

function removeErrorDownloadButtons(resource_id,download_id){
  const element = document.getElementById(`retry-cv-download-${resource_id}`);
  const elementdelay = document.getElementById(`retry-delayed-cv-download-${resource_id}`);

  updateStatusForbutton(element,download_id);
  updateStatusForbutton(elementdelay,download_id);
  removeErrorText(resource_id);

}

function updateStatusForbutton(element,download_id){
  if(element){
    const pollId = element.dataset.pollId;
    if(String(pollId)!=String(download_id)){
      changeStatusForButtons(pollId, 'closed');
    }
    element.remove();
  }

}

function removeErrorText(resource_id){
  const elementParraph = document.getElementById(`parraph-failed-error-${resource_id}`)
  const elementParraphDelay = document.getElementById(`parraph-delayed-error-${resource_id}`)

  if (elementParraph) {
    elementParraph.remove();
  }

  if (elementParraphDelay) {
    elementParraphDelay.remove();
  }

  updateErorrContainerVisibility();
}

function updateErorrContainerVisibility(){
  const modal = document.querySelector('[data-poll="modal"]');
  const errordelayed = modal.querySelector('#modal-download-delayed-messagues');
  const error = modal.querySelector('.error-msg');

  function updateVisibility(element) {
    const hasParagraphChild = Array.from(element.children).some(child => child.tagName === 'P');
    if (!hasParagraphChild) {
      element.classList.remove('d-block');
      element.classList.add('d-none');
    }
  }

  // Verificar y actualizar la visibilidad de errordelayed y error
  updateVisibility(errordelayed);
  updateVisibility(error);

}


function createRetryButton(download_id,id, name, user_id){
  if(user_id==user_of_polling){
  const spinnerCvGenerate = document.getElementById(`spinnerCvGenerate${download_id}`);
  const downloadbuttonCvGenerate = document.getElementById(`downloadbuttonCvGenerate${download_id}`);
  if (spinnerCvGenerate) { spinnerCvGenerate.remove(); downloadbuttonCvGenerate.style.display = 'block';}
  const buttonContainer = document.querySelector('.button-container.d-flex');
  const element = document.getElementById(`retry-cv-download-${id}`);
  if (!element) {  
  if (buttonContainer) {
      // Crea el botón usando una plantilla literal
      const buttonHTML = `
          <a class="uc-btn btn-cta mr-20 text-white" id="retry-cv-download-${id}" href="curriculums/generate/${id}.pdf" data-action="click->poll#removeRetryButton" data-poll-id="${download_id}" style="background-color: #F24F4F;">
              ${name}
              <i class="uc-icon ml-20 text-white">autorenew</i>
          </a>
      `;

      // Añade el botón como hijo del div encontrado
      buttonContainer.insertAdjacentHTML('beforeend', buttonHTML);
  }
  }
}
}

function createDelayedButton(download_id,id, name, user_id){
  if(user_id==user_of_polling){
 
  const buttonContainer = document.querySelector('.button-container.d-flex');
  const element = document.getElementById(`retry-delayed-cv-download-${id}`);
  if (!element) {  
  if (buttonContainer) {
      // Crea el botón usando una plantilla literal
      const buttonHTML = `
          <a class="uc-btn btn-cta mr-20" id="retry-delayed-cv-download-${id}" href="curriculums/generate/${id}.pdf" data-action="click->poll#removeRetryButton" data-poll-id="${download_id}">
              ${name}
              <i class="uc-icon ml-20">query_builder</i>
          </a>
      `;

      // Añade el botón como hijo del div encontrado
      buttonContainer.insertAdjacentHTML('beforeend', buttonHTML);
  }
  }
}
}


function handleGenerate(data){
  const name = data.resource.name;
  const url = data.file_url;
  const user_id = data.user_id;
  const resource_id = data.resource.id;
  const button = createButton(data.id, name, url, user_id,resource_id);
  buttonContainer().innerHTML += button;
  updateModalVisibility();
}

function handleDelayed(data){
  const name = data.resource.name;
  const id = data.resource.id;
  const download_id = data.id;
  const user_id = data.user_id;
  //const name = data.body.name;
  createDelayedButton(download_id,id, name, user_id)
  modal = document.querySelector('[data-poll="modal"]');
  error = modal.querySelector('#modal-download-delayed-messagues');
  error.classList.remove("d-none");
  error.classList.add("d-block");
  if (!document.getElementById(`parraph-delayed-error-${id}`)) {
    const paragraph = document.createElement('p');
    paragraph.textContent = name;
    paragraph.id = `parraph-delayed-error-${id}`;
    error.appendChild(paragraph);
  }

  showModal();
}


function handleError(data){
  const name = data.resource.name;
  const id = data.resource.id;
  const download_id = data.id;
  const user_id = data.user_id;
  //const name = data.body.name;
  createRetryButton(download_id,id, name, user_id)
  modal = document.querySelector('[data-poll="modal"]');
  error = modal.querySelector('.error-msg');
  error.classList.remove("d-none");
  error.classList.add("d-block");
  if (!document.getElementById(`parraph-failed-error-${id}`)) {
    const paragraph = document.createElement('p');
    paragraph.textContent = name;
    paragraph.id = `parraph-failed-error-${id}`;
    error.appendChild(paragraph);
  }
  showModal();
}

document.addEventListener('DOMContentLoaded', function() {
  modal = document.querySelector('[data-poll="modal"]');
  error = modal.querySelector('.error-msg');
});

function handleDownload(data){
  const filename = data.body.filename;
  const item = document.getElementById(`cv-download-${filename}`);
  if (item) {
    item.remove();
    updateModalVisibility();
  }
}

function handleClose() {

  hideModal();
}

export default class extends Controller {
  static values = { userId: Number }

  connect() {
    if(this.userIdValue){
    user_of_polling = this.userIdValue;
    this.startPolling();
    }
  }

  startPolling() {
    this.poll();
  }

  poll() {
    this.getStatus().then(response => {
      this.ShowModal(response);
      setTimeout(() => this.poll(), 5000); // Poll cada 5 segundos (ajusta según sea necesario)
    });
  }

  getStatus() {
    return fetch(`/api/downloads/status`).then(response => response.json());
  }

  changeStatus(id, status) {
    return fetch(`/api/downloads/${id}/change_status`,{
      method: 'PUT',
      headers: {"Content-type": "application/json; charset=UTF-8"},
      body: JSON.stringify({ download: { status } })
    }).then(response => response.json());
  }


  

  allChangeStatus(status) {
    return fetch(`/api/downloads/all_change_status`,{
      method: 'PUT',
      headers: {"Content-type": "application/json; charset=UTF-8"},
      body: JSON.stringify({ download: { status } })
    }).then(response => response.json());
  }

  removeButton(e){
    e.target.remove();
    this.changeStatus(e.target.dataset.pollId, 'downloaded');
    updateModalVisibility();
  }

  removeRetryButton(e){
    e.target.remove();
    this.changeStatus(e.target.dataset.pollId, 'closed');
    updateModalVisibility();
  }


  ShowModal(data) {
    const stragegy = {
      done: handleGenerate,
      error: handleError,
      delayed: handleDelayed
    };
    const datum = data.data;
    if (!datum.length) { handleClose(); }
    datum.forEach((element) => {
      if (element.status!="downloaded" && element.status!="closed"){
      stragegy[element.status](element);
      }
    });
  }

  closeModal(e) {
    this.allChangeStatus('closed');
    handleClose();
  }
}