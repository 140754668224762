import { Controller } from "stimulus";
import initSelect2 from '../plugins/select2';
import reindex from '../behaviours/namespaces/reindex-helper';

export default class extends Controller {
    static values = {
      target: String,
      input: String
    }

    connect() {
      updateRemoveButtonVisibility(this);
    }
      
    addInput(e){
      e.preventDefault();

      const $lastInput = getVisibleInputContainers(this).last();
      var $newInput = getBaseInputContainers(this).clone(true, true);
      cleanInput($newInput);
      $lastInput.after($newInput);
      
      updateRemoveButtonVisibility(this);
      reindex($newInput[0])
      initSelect2();
    }
    
    removeInput(e){
      e.preventDefault();
      const $inputContainer = $(e.target).parents('.input-multiple');
      const $input = $inputContainer.find('input[data-multiple]');
      $inputContainer.hide();
      $input.val('');
    
      if ($inputContainer.find('input[data-input-id]').length) {
        $inputContainer.find('input[data-input-destroy]').val('1');
      } else {
        $inputContainer.remove();
      }

      updateRemoveButtonVisibility(this);
    }
}

function getBaseInputContainers(controller){
  const $inputContainer = $(`#${controller.inputValue}`);
  return $($inputContainer).find('.input-multiple').first();
}


function getVisibleInputContainers(controller){
    const $inputContainer = $(`#${controller.targetValue}`);
    const $inputMultipleContainer = $inputContainer.closest('.input-multiple-container');
    const $InputContainers = $($inputMultipleContainer).find('.input-multiple');
    return $InputContainers.filter(function() {
        return this.style.display != 'none';
    });
}

function updateRemoveButtonVisibility(controller){
    const inputLenght = getVisibleInputContainers(controller).length;
    const removeButtons = document.querySelectorAll(".btn-remove")
    
    if (inputLenght > 1){
       removeButtons.forEach((element) => element.style.display = ""); 
    } else {
        removeButtons.forEach((element) => element.style.display = "none"); 
    }
}

function cleanInput(element){
    element.find('input[data-input-id]').remove();
    element.find('input[data-input-destroy]').remove();

    const $select = element.find('.js-not-select2')
    $select.removeClass('js-not-select2')
    
    const $inputs = element.find('.namespace-multiple-input')
    $inputs.each(function( i, newInput ) {
        if (newInput.tagName != "SELECT"){
          newInput.value = '';
        }
        const attributes = generateNestedNamespacesAttributes(newInput);
        const { name, id, index } = attributes;
        $(newInput).attr({
          name,
          id,
          'data-index': index
        });
    });
}

function getInputWithMajorIndex() {
    const inputOptions = document.querySelectorAll('.namespace-multiple-input');
    const inputWithMajorId = Array.from(inputOptions).reduce((before, current) => (
      (Number(before.dataset.index) > Number(current.dataset.index)) ? before : current));
    return inputWithMajorId;
  }

function generateNestedNamespacesAttributes(element) {
    const inputWithMajorIndex = getInputWithMajorIndex();
    var { index } = inputWithMajorIndex.dataset;
    var { nameTemplate, idTemplate} = element.dataset;
    const newIndex = Number(index) + 1;
  //sacar id y crear un nuevo id
    return {
      name: nameTemplate.replaceAll('index', newIndex),
      id: idTemplate.replaceAll('index', newIndex),
      index: newIndex
    };
  }