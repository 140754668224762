import 'select2/dist/js/i18n/es';

const getOptions = (select) => {
  const options = select[0].querySelectorAll('option');
  return Array.from(options).map((opt) => opt.label);
};

const userExists = (userSearched, options) => {
  let userFounded = false;
  options.forEach((user) => {
    const userSplited = user.split(' - ');
    if (userSplited[1] === userSearched) {
      userFounded = true;
    }
  });

  return userFounded;
};

const configureSelect2 = (select) => {
  const { nextElementSibling } = select;
  if (nextElementSibling && nextElementSibling.classList.contains('select2')) {
    nextElementSibling.remove();
    select.classList.remove('select2-hidden-accessible');
    select.removeAttribute('data-select2-id');
  }

  const $this = $(select);

  const options = getOptions($this);

  const isDynamic = select.dataset.dynamic;

  $this.select2({
    ...(isDynamic
      ? {
        tags: true,
        createTag(params) {
          const term = params.term.trim();

          if (term === '') {
            return null;
          }

          if (userExists(term, options)) {
            return null;
          }

          return {
            id: `id-${term}`,
            text: term
          };
        }
      }
      : {}
    ),
    language: 'es',
    width: '100%',
    dropdownParent: $this.parent(),
    templateSelection: (tag, container) => {
      const $option = $(`select option[value="${tag.id}"]`);
      if ($option.attr('locked')) {
        $(container).addClass('locked-tag');
        tag.locked = true;
      }
      return tag.text;
    }
  }).on('select2:unselecting', (e) => {
    // Cancel select2 default opening event when the clear selection button is clicked
    $this.on('select2:opening', (openingEvent) => {
      openingEvent.preventDefault();
      $this.off('select2:opening');
    });

    if ($(e.params.args.data.element).attr('locked')) {
      e.preventDefault();
    }
  });

  const clearButton = $this.next('.select2-container').find('.select2-selection__clear');
  clearButton.addClass('uc-btn btn-primary');
};

export default function initSelect2(selectGiven) {
  if (selectGiven) {
    configureSelect2(selectGiven);
  } else {
    $('select')
      .not('.js-not-select2')
      .not('[class^="flatpickr"]')
      .each((_, select) => {
        configureSelect2(select);
      });
  }
}
