let errorShown = false;

function getExternalReference(id, context, callback) {
  $.ajax({
    url: `/api/activity_fields/${id}/options`,
    context
  }).done(callback)
    .fail(() => {
      if (!errorShown) {
        errorShown = true;
        window.showWarningAlert('Ha ocurrido un error obteniendo información externa');
      }
    });
}

export default function initExternalReferences() {
  // Edit form
  const externalReferences = document.querySelectorAll("[data-is-external='true']:not(select)");
  const selects = document.querySelectorAll("select[data-is-external='true']");

  // Disabled all selects until we load the available options.
  selects.forEach((select) => { select.disabled = true; });

  if (externalReferences.length > 0) {
    externalReferences.forEach((reference) => {
      const referenceID = reference.dataset.activityFieldId;

      getExternalReference(referenceID, reference, (data) => {
        const self = reference;
        const { rawValue, activityFieldId } = self.dataset; // ID reference in external model
        const selectReference = document.querySelectorAll(
          `select[data-activity-field-id='${activityFieldId}']`
        )[0];
        const isEditable = !selectReference.dataset.externalUneditable;
        let currentValue = rawValue;
        let optionsLen = 0;
        let optionKey;
        let optionValue;

        for (let i = 0; i < data.length; i += 1) {
          optionKey = data[i].id.toString();
          optionValue = data[i].value;

          selectReference.options[optionsLen] = new Option(optionValue, optionKey);
          if (optionKey === rawValue) {
            currentValue = data[i].value;
            self.textContent = currentValue;
            selectReference.options[optionsLen].selected = true;
          }

          optionsLen = selectReference.options.length;
        }

        if (isEditable) {
          selectReference.disabled = false;
        }
      });
    });
  }

  // Create form
  const createForm = document.querySelectorAll('form#createForm');

  if (createForm.length > 0) {
    createForm.forEach((form) => {
      const formSelects = form.querySelectorAll(
        "select[data-is-external='true']"
      );

      formSelects.forEach((select) => {
        const referenceID = select.dataset.activityFieldId;
        getExternalReference(referenceID, select, (data) => {
          const self = select;
          const $hiddenValueInput = $(`input[type="hidden"][name="${select.name}"]`);
          const isEditable = !self.dataset.externalUneditable;
          let optionsLen = 0;
          let optionKey;
          let optionValue;

          for (let i = 0; i < data.length; i += 1) {
            optionKey = data[i].id;
            optionValue = data[i].value;
            self.options[optionsLen] = new Option(optionValue, optionKey);
            optionsLen = self.options.length;
          }

          if (isEditable) {
            self.disabled = false;
          }

          if ($hiddenValueInput.length > 0) {
            $hiddenValueInput.remove();
          }
        });
      });
    });
  }
}
