import { Controller } from "stimulus";

export default class extends Controller {
  inputsIds = []; 
  
  initialize() {
    this.inputsIdsValue = []; 
  }

  addToModifiedList(originalValue, newValue, value, fieldId) {
    if((originalValue!= newValue)&&(value === 'true')) {
      
      const index = this.inputsIdsValue.indexOf(fieldId.toString());
      if (index == -1) {
        this.inputsIdsValue.push(fieldId.toString());
      }
    }
    else if ((originalValue == newValue)&&(value === 'true')) {
      const index = this.inputsIdsValue.indexOf(fieldId.toString());
      if (index !== -1) {
        this.inputsIdsValue.splice(index, 1);
      }
    }
  }

  inputVerify(input) {
    const orginalValue= input.dataset.rawValue;
    const newValue=input.value;
    const fieldId = input.dataset.activityFieldId;
    const value = input.dataset.activityinstanceIsexternaldataValue;

    if(input.parentNode.classList.contains("input-multiple")){
      this.validateMultipleInput(input);
    }else
    {
      this.addToModifiedList(orginalValue,newValue,value,fieldId);
    }
    
  }

  validateMultipleInput(input){
    const fieldId = input.dataset.activityFieldId;
    const value = input.dataset.activityinstanceIsexternaldataValue;
    const divPadre = input.parentNode;
    const elementosHijos = divPadre.parentNode.querySelectorAll('input.uc-input-style');
    var changedValues = ""
    var orginalValue = ""
    const inputValues = [];
    const inputOriginalValues = [];
          for (let h = 0; h < elementosHijos.length; h++) {
            const inputOfDiv = elementosHijos[h];
            
            inputValues.push(inputOfDiv.value); 
            if(inputOfDiv.getAttribute('orginal_data') !== null && inputOfDiv.getAttribute('orginal_data')!=""){
            inputOriginalValues.push(inputOfDiv.getAttribute('orginal_data')); 
          }
          }
    changedValues = inputValues.filter(item => item !== "").join(', ');
    orginalValue =inputOriginalValues.join(', ');

    this.addToModifiedList(orginalValue,changedValues,value,fieldId);

  }
  
  changeValue(event){
    const input = event.target;
    this.inputVerify(input);  
  }

  removeValue(event){
    var span =event.target;
    var div = span.parentNode.parentNode;
    var  input = div.querySelectorAll('input.uc-input-style')[0];
    this.inputVerify(input);
  }
   


  modalAlert(event){
    var ownership_status = document.getElementById("ownership_status_activity");
    if(ownership_status.value != "academic"){
      if(this.inputsIdsValue.length>0){
      event.preventDefault();
      
      var div = document.getElementById("modalfieldschanged");
      div.innerHTML = "";

      for (let i = 0; i < this.inputsIdsValue.length; i++) {
        const element = this.inputsIdsValue[i];
        const searchInput = document.querySelector('input[data-activity-field-id="'+element+'"]');
        var changedValues = searchInput.value;
        var orginalValue = searchInput.getAttribute('orginal_data')
        if (searchInput.parentNode.classList.contains("input-multiple")){
          const divPadre = searchInput.parentNode;
          const elementosHijos = divPadre.parentNode.querySelectorAll('input.uc-input-style');
          
         if(elementosHijos.length>1){
          const inputValues = [];
          const inputOriginalValues = [];
          for (let h = 0; h < elementosHijos.length; h++) {
            const inputOfDiv = elementosHijos[h];
            
            inputValues.push(inputOfDiv.value); 
            if(inputOfDiv.getAttribute('orginal_data') !== null && inputOfDiv.getAttribute('orginal_data')!=""){
            inputOriginalValues.push(inputOfDiv.getAttribute('orginal_data')); 
          }
          }
          changedValues = inputValues.filter(item => item !== "").join(', ');
          orginalValue =inputOriginalValues.join(', ');
         } 
     
        }

        const parragraph = document.createElement('p');
        
        parragraph.innerHTML= "se cambió <strong>"+ searchInput.getAttribute('field_name')+ 
        "</strong>: de "+ '"'+orginalValue + '"' + " <strong>a</strong> "+ '"' +changedValues+ '"';
  
        if (orginalValue.length!=0 && changedValues.length==0){
          parragraph.innerHTML= "Se eliminó valor <strong>"+ searchInput.getAttribute('field_name')+ 
        "</strong> ("+ '"'+orginalValue + '")';

        }

        if (orginalValue.length==0 && changedValues.length!=0){
          parragraph.innerHTML= "Se agregó valor a <strong>"+ searchInput.getAttribute('field_name')+ 
        "</strong> "+ '"'+changedValues + '"';
        }
        div.appendChild(parragraph);
      }

      const boton = document.getElementById('open-edit-modal-button'); 
      boton.click();
     
    }
  }
}   
    
}
