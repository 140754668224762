import { showAlert, initWrapperAlerts } from '../fixed-alerts';
import {
  setModal, MODAL_ACTIVITY_TEMPLATE, replacePrimaryButton, MODAL_STRATEGIC_TAG
} from './modal';

/* eslint-disable no-use-before-define */
let activityTemplatesAssigned = [];
let searchText = '';
let activityTemplatesActivated = [];

const ACTIVITY_TEMPLATES_ENDPOINT = '/api/activity_templates';
const TAG_ID = window.location.pathname.split('/')[2];

const fetchActivityTemplates = async (page) => {
  const response = await fetch(`${ACTIVITY_TEMPLATES_ENDPOINT}?${new URLSearchParams({
    page: page || 1,
    search_term: searchText
  })}`).then((res) => res);

  if (response.status === 200) {
    const data = await response.json();
    const activityTemplatesData = {
      data,
      currentPage: Number(response.headers.get('Current-Page')),
      pageItems: Number(response.headers.get('Page-Items')),
      totalCount: Number(response.headers.get('Total-Count')),
      totalPages: Number(response.headers.get('Total-Pages')),
    };

    return activityTemplatesData;
  }
  showAlert('error', 'cancel', 'Ha ocurrido un error al buscar los subtipos');

  const activityTemplatesData = {
    data: [],
    currentPage: 0,
    pageItems: 0,
    totalCount: 0,
    totalPages: 0,
  };

  return activityTemplatesData;
};

const assignActivityTemplateToTag = async (activityTemplateId, isChecked, destroy) => {
  const response = await fetch(`${ACTIVITY_TEMPLATES_ENDPOINT}/${activityTemplateId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      activity_template_tags_attributes: [
        {
          tag_id: TAG_ID,
          _destroy: destroy
        }
      ]
    })
  }).then((res) => res);

  const activityTemplateSwitch = document.getElementById(`activity-template-switch-${activityTemplateId}`);

  if (response.status === 200) {
    activityTemplateSwitch.checked = isChecked;
    showAlert('success', 'check_circle', `Subtipo ${isChecked ? 'seleccionado' : 'deseleccionado'} correctamente`);
    if (!isChecked) {
      activityTemplatesActivated = activityTemplatesActivated
        .filter((activityTemplateIdItem) => activityTemplateIdItem !== activityTemplateId);
    } else {
      activityTemplatesActivated.push(activityTemplateId);
    }
  } else {
    showAlert('error', 'cancel', 'Ha ocurrido un error');
    activityTemplateSwitch.checked = !activityTemplateSwitch.checked;
  }
};

const renderSearchInput = () => {
  const searchForm = document.createElement('form');
  searchForm.className = 'mb-36';

  searchForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const activityTemplatesData = await fetchActivityTemplates();
    clearList();
    renderList(activityTemplatesData);
  });

  const searchInput = document.createElement('input');
  searchInput.name = 'search';
  searchInput.value = searchText;
  searchInput.addEventListener('change', (e) => {
    searchText = e.target.value;
  });
  searchInput.className = 'uc-input-style w-icon search';
  searchInput.placeholder = 'Buscar por';

  searchForm.appendChild(searchInput);

  return searchForm;
};

const renderPagination = (
  previousPage,
  nextPage,
  totalPages,
  currentPage,
) => {
  const pagination = document.createElement('nav');
  pagination.className = 'uc-pagination';

  if (previousPage) {
    const previousPageButton = document.createElement('button');
    previousPageButton.addEventListener('click', () => changePage(currentPage - 1));
    previousPageButton.type = 'button';
    previousPageButton.className = 'uc-pagination_prev mr-12';
    const icon = document.createElement('i');
    icon.className = 'uc-icon';
    icon.textContent = 'keyboard_arrow_left';

    previousPageButton.appendChild(icon);
    pagination.appendChild(previousPageButton);
  }

  const listOfPages = document.createElement('ul');
  listOfPages.className = 'uc-pagination_pages';
  pagination.appendChild(listOfPages);

  for (let i = 1; i <= totalPages; i += 1) {
    const page = document.createElement('li');
    page.classList.add('page-item');

    const button = document.createElement('button');
    button.className = 'page-link';
    button.type = 'button';
    button.addEventListener('click', () => changePage(i));

    page.appendChild(button);

    const pageDots = document.createElement('li');
    pageDots.classList.add('page-item');

    const dots = document.createElement('span');
    dots.className = 'page-link';
    dots.textContent = '...';

    pageDots.appendChild(dots);

    if (i === currentPage) {
      page.classList.add('active');
      button.textContent = currentPage;
    }

    if ((i < currentPage + 3 && i > currentPage - 3) || (i < 5 || i > totalPages - 4)) {
      button.textContent = i;
    }

    if (i === currentPage + 4) {
      listOfPages.appendChild(pageDots);
    }

    if (button.textContent !== '') {
      listOfPages.appendChild(page);
    }

    if (i === currentPage - 4) {
      listOfPages.appendChild(pageDots);
    }
  }

  if (nextPage) {
    const nextPageButton = document.createElement('button');
    nextPageButton.addEventListener('click', () => changePage(currentPage + 1));
    nextPageButton.type = 'button';
    nextPageButton.className = 'uc-pagination_next ml-12';
    const icon = document.createElement('i');
    icon.className = 'uc-icon';
    icon.textContent = 'keyboard_arrow_right';

    nextPageButton.appendChild(icon);
    pagination.appendChild(nextPageButton);
  }

  return pagination;
};

const renderTotalItems = (currentPage, pageItems, total) => {
  const from = currentPage === 1 ? 1 : ((currentPage - 1) * pageItems) + 1;
  let to = currentPage * pageItems;

  if (to > total) {
    to = total;
  }

  const totalContainer = document.createElement('div');
  totalContainer.className = 'd-flex justify-content-end';
  totalContainer.innerHTML = `
    <span class="p-color--gray">Resultados del ${from} al ${to} de ${total}</span>
  `;
  return totalContainer;
};

const renderBreadCrumbs = (activityKind, activityGroups, activityTemplate) => {
  const breadcrumbs = document.createElement('div');

  breadcrumbs.innerHTML = `
    <nav aria-label="breadcrumb">
      <ol class="m-0 uc-breadcrumb">
        <li class="uc-breadcrumb_item">
          <a href="/tipos/${activityKind.id}">${activityKind.display_name}</a>
          <i aria-hidden="true" class="uc-icon">keyboard_arrow_right</i>
        </li>
        <li class="uc-breadcrumb_item">
          <a href="/grupos/${activityGroups.id}">${activityGroups.display_name}</a>
          <i aria-hidden="true" class="uc-icon">keyboard_arrow_right</i>
        </li>
        <li class="uc-breadcrumb_item current" aria-current="page">
          <span>${activityTemplate}</span>
        </li>
      </ol>
    </nav>
  `;

  return breadcrumbs.innerHTML;
};

const renderActivityTemplateListItem = (activityTemplate) => {
  const { activity_group: activityGroup } = activityTemplate;
  const { activity_kind: activityKind } = activityGroup;
  const { display_name: displayName } = activityTemplate;
  const activityTemplateListItem = document.createElement('div');
  activityTemplateListItem.innerHTML = `
    <div class="uc-card mb-3">
      <div class="py-3 px-4 uc-card_body">
        <div class="mb-2 d-flex justify-content-between align-items-center">
          <h3 class="fs-6">${displayName}</h3>
          <div class="form-switch">
            <input name="tag[is_strategic]" type="hidden" value="0">
            <input
              data-mtarget="generic-tag-modal"
              ${activityTemplatesAssigned.find((activityTemplateAssigned) => activityTemplateAssigned.id === activityTemplate.id) && 'checked'}
              aria-describedby="hiddenFeedback"
              id="activity-template-switch-${activityTemplate.id}"
              data-activity-template-id="${activityTemplate.id}"
              class="form-check-input activity-template-switch"
              data-bs-toggle="collapse"
              type="checkbox">
          </div>
        </div>
        ${activityTemplate.description !== null ? `<p class="p-size--sm p-color--gray">${activityTemplate.description}</p>` : ''}
        ${renderBreadCrumbs(activityKind, activityGroup, displayName)}
      </div>
    </div>
  `;
  return activityTemplateListItem;
};

const handleChangeActivityTemplateSwitch = (e, activityTemplateId) => {
  const { target: { checked, dataset: { mtarget } } } = e;

  if (!checked) {
    setModal(MODAL_ACTIVITY_TEMPLATE);
    e.target.checked = !checked;
    const modal = document.querySelector(`[data-modal="${mtarget}"]`);
    modal.style = 'display: block';

    replacePrimaryButton(() => {
      assignActivityTemplateToTag(activityTemplateId, checked, !checked);
      modal.style = 'display: none';
    });
  } else {
    assignActivityTemplateToTag(activityTemplateId, checked, !checked);
  }
};

const renderList = (activityTemplatesData) => {
  const listContainer = document.createElement('div');
  const {
    data: activityTemplates,
    currentPage,
    pageItems,
    totalCount,
    totalPages
  } = activityTemplatesData;
  const previousPage = currentPage !== 1;
  const nextPage = currentPage !== totalPages;

  activityTemplates.forEach((activityTemplate) => {
    const activityTemplateListItem = renderActivityTemplateListItem(activityTemplate);
    listContainer.appendChild(activityTemplateListItem);
  });

  const activityTemplatesListContent = document.getElementById('activity-templates-list-content');

  if (activityTemplatesListContent) {
    activityTemplatesListContent.appendChild(renderSearchInput());
    activityTemplatesListContent.appendChild(listContainer);
    activityTemplatesListContent.appendChild(renderTotalItems(currentPage, pageItems, totalCount));
    activityTemplatesListContent.appendChild(renderPagination(
      previousPage, nextPage, totalPages, currentPage
    ));

    const activityTemplateSwitches = document.querySelectorAll('.activity-template-switch');

    activityTemplateSwitches.forEach((activityTemplateSwitch) => {
      const { dataset: { activityTemplateId } } = activityTemplateSwitch;
      if (activityTemplatesActivated
        .find((activityTemplateItem) => activityTemplateItem === activityTemplateId)) {
        activityTemplateSwitch.checked = true;
      }
      activityTemplateSwitch.addEventListener(('change'), (e) => handleChangeActivityTemplateSwitch(e, activityTemplateId));
    });
  }
};

const clearList = () => {
  const activityTemplatesListContent = document.getElementById('activity-templates-list-content');
  activityTemplatesListContent.innerHTML = '';
};

const changePage = async (page) => {
  const activityTemplatesData = await fetchActivityTemplates(page);
  clearList();
  renderList(activityTemplatesData);
};

const getDataAndRender = async () => {
  const activityTemplatesData = await fetchActivityTemplates();
  renderList(activityTemplatesData);
};

const onChangeStrategicAxisSwitch = async (e) => {
  const { target: { checked, dataset: { mtarget, targetID } } } = e;
  const modal = document.querySelector(`[data-modal="${mtarget}"]`);
  const targetCollapsible = document.querySelector(targetID);

  if (!checked && modal) {
    e.target.checked = true;
    setModal(MODAL_STRATEGIC_TAG);

    replacePrimaryButton(() => {
      e.target.checked = false;
      modal.style = 'display: none';
      $(targetCollapsible).slideToggle();
    });
  } else if (modal) {
    $(targetCollapsible).slideToggle();
    modal.style = 'display: none';
  }

  if (!modal) {
    $(targetCollapsible).slideToggle();
  }
};

export default async function initStrategicAxisSwitch() {
  const activityTemplatesTag = document.getElementById('activity-templates-input-hidden');
  if (activityTemplatesTag) {
    activityTemplatesAssigned = JSON.parse(activityTemplatesTag.value);
  }
  const strategicAxisSwitch = document.getElementById('strategic-axis-switch');
  initWrapperAlerts();

  if (strategicAxisSwitch) {
    strategicAxisSwitch.addEventListener('change', onChangeStrategicAxisSwitch);
    getDataAndRender();
  }
}
