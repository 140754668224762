import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';

/*
 * Add event listener to input of type date to handle change on value and
 * hide custom label.
 */
export default function initInputDate() {
  const inputDate = document.querySelectorAll('.input-date');

  if (inputDate) {
    inputDate.forEach((inputDateItem) => {
      const options = {
        locale: Spanish,
        dateFormat: 'Y-m-d',
        allowInput: false
      };
      flatpickr(inputDateItem, options);

      // Avoid open calendar.
      inputDateItem.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
        }
      });
    });
  }
}
