const INVALID_CLASS_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove'
};

const MESSAGE_ERRORS = {
  REQUIRED: 'Este campo es requerido',
  REQUIRED_NUMBER: 'Este campo es requerido y debe ser numérico',
  ORDER_REPEATED: (cardName) => `El orden ingresado coincide con el de la carta con nombre: ${cardName}`
};

const getErrorElement = (input) => {
  const { parentElement, name } = input;
  const elementError = parentElement.querySelector('.invalid-feedback');

  if (!elementError) {
    throw new Error(`Falta agregar la clase invalid-feedback al input ${name}`);
  }

  return elementError;
};

const addOrRemoveInvalidClassToInput = (input, action) => {
  input.classList[action]('is-invalid');
};

const changeErrorMessageOfInput = (input, message) => {
  const elementError = getErrorElement(input);
  elementError.textContent = message;
};

const scrollToInvalidInput = (input) => {
  input.scrollIntoView({ behavior: 'smooth', block: 'center' });

  setTimeout(() => {
    input.focus();
  }, 500);
};

const handleRequiredErrors = (input) => {
  const { value, type } = input;
  const currentMessage = getErrorElement(input).textContent;
  const requiredMessage = type === 'number' ? MESSAGE_ERRORS.REQUIRED_NUMBER : MESSAGE_ERRORS.REQUIRED;

  if (!currentMessage || currentMessage === requiredMessage) {
    const action = value ? INVALID_CLASS_ACTIONS.REMOVE : INVALID_CLASS_ACTIONS.ADD;
    const message = value ? '' : requiredMessage;
    addOrRemoveInvalidClassToInput(input, action);
    changeErrorMessageOfInput(input, message);
  }
};

const getInputToFocus = (input, inputToFocus) => {
  const { value } = input;

  if (!value) {
    return input;
  }

  return inputToFocus;
};

const handleSubmit = (e, form) => {
  e.preventDefault();
  const requiredAndEnabledInputs = form.querySelectorAll('[required]:not([disabled])');
  const invalidInputs = Array.from(requiredAndEnabledInputs).filter((input) => !input.value);
  let inputToFocus;

  if (invalidInputs.length) {
    Array.from(requiredAndEnabledInputs).reverse().forEach((input) => {
      handleRequiredErrors(input);
      inputToFocus = getInputToFocus(input, inputToFocus);
    });
  } else {
    form.submit();
  }

  if (inputToFocus) {
    scrollToInvalidInput(inputToFocus);
  }
};

const initActivityTemplateVisualizationFormErrors = () => {
  const visualizationCardForms = document.querySelectorAll('[data-visualization-card-form]');

  visualizationCardForms.forEach((form) => {
    form.addEventListener('submit', (e) => handleSubmit(e, form));
  });
};

export {
  initActivityTemplateVisualizationFormErrors,
  addOrRemoveInvalidClassToInput,
  changeErrorMessageOfInput,
  INVALID_CLASS_ACTIONS,
  MESSAGE_ERRORS
};
