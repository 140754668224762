function getInputWithMajorIndex() {
  const inputOptions = document.querySelectorAll('.namespace-multiple-input');
  const inputWithMajorId = Array.from(inputOptions).reduce((before, current) => (
    (Number(before.dataset.index) > Number(current.dataset.index)) ? before : current));
  return inputWithMajorId;
}

export default function generateNestedNamespacesAttributes(element) {
  const inputWithMajorIndex = getInputWithMajorIndex();
  const { index } = inputWithMajorIndex.dataset;
  const { nameTemplate, idTemplate} = element.dataset;
  const newIndex = Number(index) + 1;

  return {
    name: nameTemplate.replaceAll('index', newIndex),
    id: idTemplate.replaceAll('index', newIndex),
    index: newIndex
  };
}
