export default function initRowLink() {
  const rowLink = document.querySelectorAll('.row-link');

  if (rowLink) {
    rowLink.forEach((rowLinkItem) => {
      rowLinkItem.addEventListener('click', (event) => {
        const linkTo = event.target.parentElement.dataset.href;
        if (linkTo) {
          window.location = linkTo;
        }
      });
    });
  }
}
