import * as Routes from '../routes.js.erb';
import {
  Controller
} from "stimulus";

export default class extends Controller {

  updateAutoCubeImport(event){
    const id = event.target.dataset.taskexecutionIdValue;
    FetchUpdate(Routes.auto_cube_import_api_activity_template_path(id))
  }
}

function FetchUpdate(url) {
  fetch(url,{
    method: 'PUT',
      headers: {
      'Content-Type': 'application/json',
        'Accept': 'application/json'
    }})
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
    })
    .catch(error => {
      console.error('Fetch failed:', error);
    });
}




