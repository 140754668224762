import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    const modal = this.modalTarget;
    if (modal) {

      modal.style.display = 'block';
      modal.style.visibility = 'visible';
      modal.setAttribute('open', true);


      const event = new Event('open');  
      modal.dispatchEvent(event);       
    }
  }
}
