export default function initResetButton() {
  const btnResets = document.querySelectorAll('button#btnResetForm');

  if (btnResets) {
    btnResets.forEach((btnReset) => {
      btnReset.addEventListener('click', (event) => {
        const btn = event.currentTarget;
        const idForm = btn.getAttribute('form');
        if (idForm) {
          const form = document.querySelector(`form#${idForm}`);
          if (form) {
            // form.reset() only works before the submit action.
            // Reset the form by leaving the value empty to cover
            // the case after submitting the form.
            const fields = form.querySelectorAll('input');
            fields.forEach((field) => {
              field.value = '';
            });
          }
        }
      });
    });
  }
}
