import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "title", "description"]

  connect() {
  }
  
  openModal(event) {
    const button = event.currentTarget
    const title = button.getAttribute("data-title")
    const description = button.getAttribute("data-description")

    document.getElementById('activityTemplateTitle').innerHTML = title
    document.getElementById('activityTemplateDescription').innerHTML = description
    const modal = document.getElementById('modalGeneric');
    modal.classList.add("d-block")
    modal.classList.remove("d-none")
  }

  closeModal() {
    const modal = document.getElementById('modalGeneric');
    modal.classList.remove("d-block")
    modal.classList.add("d-none")
    document.getElementById('activityTemplateTitle').innerHTML = ''
    document.getElementById('activityTemplateDescription').innerHTML = ''
  }
  handleClickOutside(event) {
    const modal = document.getElementById('modalGeneric');
    if (!modal.contains(event.target) && modal.classList.contains("d-block")) {
      this.closeModal()
    }
  }

  handleKeydown(event) {
    const modal = document.getElementById('modalGeneric');
    if (event.key === "Escape" && modal.classList.contains("d-block")) {
      this.closeModal()
    }
  }
}
