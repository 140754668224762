import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';

bootstrap.Tooltip.Default.allowList.a.push('data-method');
bootstrap.Tooltip.Default.allowList.button = [];

export function initPopover() {
  document
    .querySelectorAll('[data-bs-toggle="popover"]')
    .forEach((popoverTriggerEl) => {
      const popover = new bootstrap.Popover(popoverTriggerEl, { html: true });
      popover.setContent();

      popoverTriggerEl.addEventListener('shown.bs.popover', () => {
        const popoverElement = popover.getTipElement();
        const popoverCloseBtn = popoverElement.querySelector('.close');

        if (popoverCloseBtn) {
          popoverCloseBtn.addEventListener('click', (e) => {
            e.preventDefault();
            popover.hide();
          });
        }
      });

      return popover;
    });
}

export function initTooltip() {
  document
    .querySelectorAll('[data-bs-toggle="tooltip"]')
    .forEach((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));
}
