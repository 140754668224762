import initSelect2 from '../../plugins/select2';
import { changeFieldNames } from './form-activity-template-visualization-card-create-field';

const initFieldInput = (fieldOptions, cardContext) => {
  let fieldSelects = null;

  if (cardContext) {
    fieldSelects = cardContext.querySelectorAll('[data-reference-input]');
  } else {
    fieldSelects = document.querySelectorAll('[data-reference-input]');
  }
  Array.from(fieldSelects).forEach((select) => {
    $(select).on('change', (e, programmaticallyTrigger) => {
      const { target: { value }, target } = e;
      const hiddenFieldId = select.previousElementSibling;
      hiddenFieldId.value = value;
      if (value && !programmaticallyTrigger) {
        const field = fieldOptions.find((fieldOption) => fieldOption.id === Number(value));
        const index = target.id.split('-')[1];
        const kindSelect = document.getElementById(`kind-${index}`);
        $(kindSelect).val(field.kind).trigger('change', true);
      }
    });
  });
};

const handleCollapseElementVisibility = (show, index, id) => {
  const element = document.getElementById(`${id}-${index}`);

  if (!element) return;

  if (show) {
    element.classList.replace('collapse', 'show');
  } else if (element) {
    element.classList.replace('show', 'collapse');
  }
};

const toggleFieldsAvailability = (cardId, activityFieldId) => {
  const card = document.getElementById(`cardId-form-${cardId}-visualization`);
  const activityField = card.querySelector(`[data-field-id="${activityFieldId}"]`);
  const fields = activityField.querySelectorAll('input, select');
  Array.from(fields).forEach((field) => {
    const { tagName } = field;
    const closetsCollapseElement = field.closest('.collapse:not(.show)');
    const isHidden = !!closetsCollapseElement && closetsCollapseElement.id !== `activity-template-collapse-${activityFieldId}`;
    field.disabled = isHidden ? true : null;

    if (tagName === 'SELECT' && isHidden) {
      initSelect2(field);
    }
  });
};

const initializeKindSelects = (fieldOptions, cardContext) => {
  let kindSelects = null;

  if (cardContext) {
    kindSelects = cardContext.querySelectorAll('.kind-select');
  } else {
    kindSelects = document.querySelectorAll('.kind-select');
  }

  Array.from(kindSelects).forEach((select) => {
    $(select).on('change', (e, programmaticallyTrigger) => {
      const { target: { value, id: completeId } } = e;

      const [restOfId, cardId] = completeId.split('_');
      const restOfIdSplitted = restOfId.split('-');
      const indexOfField = restOfIdSplitted[restOfIdSplitted.length - 1];
      const activityFieldId = `${indexOfField}_${cardId}`;

      handleCollapseElementVisibility(value.includes('choices'), activityFieldId, 'namespace');
      handleCollapseElementVisibility(value.includes('reference'), activityFieldId, 'reference');
      handleCollapseElementVisibility(value.includes('external_reference'), activityFieldId, 'url');
      handleCollapseElementVisibility(value.includes('date'), activityFieldId, 'visualization-type');
      toggleFieldsAvailability(cardId, activityFieldId);

      if (!programmaticallyTrigger) {
        const fieldsFiltered = fieldOptions.filter(({ kind }) => kind === value);
        const referenceFieldSelect = document.getElementById(`reference_id-${activityFieldId}`);

        $(referenceFieldSelect).empty();
        fieldsFiltered.forEach(({ id, label }) => {
          $(referenceFieldSelect).append(`<option value="${id}">${label}</option>`);
        });
        $(referenceFieldSelect).val(null).trigger('change', true);
      }
    });
  });
};

const temporarilyDisable = (input) => {
  input.disabled = 'true';

  setTimeout(() => {
    input.removeAttribute('disabled');
  }, 300);
};

const handleClickFieldReferenceCheckbox = (e, card, activityFieldId, activityField) => {
  const { target: { checked }, target } = e;
  temporarilyDisable(target);

  const fieldSelect = card.querySelector(`#reference_id-${activityFieldId}`);
  const labelHiddenInput = card.querySelector(`#label-${activityFieldId}`);
  const nameHiddenInput = card.querySelector(`#name-${activityFieldId}`);
  const idHiddenInput = card.querySelector(`#field_id-${activityFieldId}`);

  labelHiddenInput.disabled = checked ? true : null;
  nameHiddenInput.disabled = checked ? true : null;
  idHiddenInput.disabled = !checked ? true : null;

  fieldSelect.disabled = !checked ? true : null;
  fieldSelect.value = '';

  const elementsToChangeName = activityField.querySelectorAll(`[data-name-changed=${checked}]`);
  changeFieldNames(elementsToChangeName, !checked);

  initSelect2(fieldSelect);
};

const initFieldReferenceCheckbox = (cardContext) => {
  let checkboxes = null;

  if (cardContext) {
    checkboxes = cardContext.querySelectorAll('[data-field-reference-checkbox]');
  } else {
    checkboxes = document.querySelectorAll('[data-field-reference-checkbox]');
  }

  checkboxes.forEach((checkbox) => {
    const { id: completeId } = checkbox;
    const [restOfId, cardId] = completeId.split('_');
    const restOfIdSplitted = restOfId.split('-');
    const indexOfField = restOfIdSplitted[restOfIdSplitted.length - 1];
    const activityFieldId = `${indexOfField}_${cardId}`;
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(Number(indexOfField))) {
      const card = document.getElementById(`cardId-form-${cardId}-visualization`);
      const activityField = card.querySelector(`[data-field-id="${activityFieldId}"]`);
      checkbox.addEventListener('change', (e) => handleClickFieldReferenceCheckbox(e, card, activityFieldId, activityField));
    }
  });
};

const handleClickNamespaceCheckbox = (e, activityFieldId) => {
  const { target: { checked }, target } = e;
  temporarilyDisable(target);

  const fieldSelect = document.getElementById(`namespace_id-${activityFieldId}`);
  const namespaceName = document.getElementById(`namespace_name-${activityFieldId}`);
  const namespaceOptions = document.getElementById(`namespace_options-${activityFieldId}`);

  fieldSelect.disabled = checked ? null : 'true';
  namespaceName.disabled = !checked ? null : 'true';
  namespaceOptions.disabled = !checked ? null : 'true';

  initSelect2(fieldSelect);
};

const initNamespaceCheckbox = (cardContext) => {
  let checkboxes = null;

  if (cardContext) {
    checkboxes = cardContext.querySelectorAll('[data-namespace-checkbox]');
  } else {
    checkboxes = document.querySelectorAll('[data-namespace-checkbox]');
  }

  checkboxes.forEach((checkbox) => {
    const { id: completeId } = checkbox;
    const [restOfId, cardId] = completeId.split('_');
    const restOfIdSplitted = restOfId.split('-');
    const indexOfField = restOfIdSplitted[restOfIdSplitted.length - 1];
    const activityFieldId = `${indexOfField}_${cardId}`;
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(Number(indexOfField))) {
      checkbox.addEventListener('change', (e) => handleClickNamespaceCheckbox(e, activityFieldId));
    }
  });
};

const initFieldLabelInput = (cardContext) => {
  let inputs = null;

  if (cardContext) {
    inputs = cardContext.getElementsByClassName('field-label');
  } else {
    inputs = document.getElementsByClassName('field-label');
  }

  Array.from(inputs).forEach((input) => {
    input.addEventListener('change', (e) => {
      const { target: { value }, target } = e;
      const nameHiddenInput = target.previousElementSibling;
      const labelHiddenInput = nameHiddenInput.previousElementSibling;
      nameHiddenInput.value = value;
      labelHiddenInput.value = value;
    });
  });
};

const getShortUrl = (url) => {
  try {
    const urlObj = new URL(url);
    const { protocol, hostname } = urlObj;
    const urlBase = `${protocol}//${hostname}`;
    return urlBase;
  } catch (error) {
    return url;
  }
};

const initExternalDataUrlInput = (cardContext) => {
  let externalUrlInputs = null;

  if (cardContext) {
    externalUrlInputs = cardContext.querySelectorAll('[data-external-url]');
  } else {
    externalUrlInputs = document.querySelectorAll('[data-external-url]');
  }

  Array.from(externalUrlInputs).forEach((input) => {
    input.addEventListener('change', (e) => {
      const { target: { value }, target } = e;
      const inputName = target.previousElementSibling;
      const nameValue = getShortUrl(value);
      inputName.value = nameValue;
    });
  });
};

export {
  initExternalDataUrlInput,
  initFieldLabelInput,
  initNamespaceCheckbox,
  initFieldReferenceCheckbox,
  initializeKindSelects,
  initFieldInput,
};
