import { DateTime } from 'luxon';
import * as Routes from '../../routes.js.erb';

/**
 * Transforms a string date into a human readable text relative to the current date using Luxon
 * @param {string} dateString - A string that can be converted to a valid date with the Date API
 * @returns string.
 */
function formatHistoryDate(dateString) {
  const date = new Date(dateString);
  return DateTime.fromJSDate(date).setLocale('es').toRelative();
}

/**
 * Generates an span with a human readable date as text content.
 * @param {string} date - A valid date string
 * @returns HTMLSpanElement - Contains the formatted date and styles
 */
function getDateElement(date) {
  const element = document.createElement('span');
  element.classList.add('p-color--gray', 'p-size--sm');
  element.textContent = formatHistoryDate(date);
  return element;
}

/**
 * Generates a p element with the 'message' parameter as text content.
 * @param {string} message - The text to be displayed inside the paragraph
 * @returns HTMLParagraphElement
 */
function getMessageElement(message) {

  const p = document.createElement('p');
  p.classList.add('p-color--gray', 'p-size--lg', 'mb-0', 'mt-1');
  p.innerHTML = message;
  if (message =='El ingreso fue creado con éxito. Se están procesando la(s) actividad(es) ingresada(s).'){
    p.id = 'upload_was_crated_succefull';
  }
  return p;
}

/**
 * Generates an anchor with an icon. Uses the url parameter as href.
 * @param {string} url - The url to be used in the anchor's href parameter
 * @returns HTMLAnchorElement
 */
function getErrorLinkElement(url) {


  if(url === '__Error__') {
    const p = document.createElement('p');
    const icon = document.createElement('span');
    p.textContent = 'Ha habido un problema con el archivo. Este error ha sido informado para su corrección y se está trabajando ello';
    icon.classList.add('uc-icon', 'material-icons', 'm-0');
    icon.ariaHidden = 'true';
    icon.textContent = 'error';
    p.appendChild(icon);
    return p;
  } else {
    const a = document.createElement('a');
    const icon = document.createElement('span');

    a.href = url;

    a.textContent = 'Descargar archivo de errores';

    icon.classList.add('uc-icon', 'material-icons', 'm-0');
    icon.ariaHidden = 'true';
    icon.textContent = 'download';
    a.appendChild(icon);
    return a;
  }

}

/**
 * Generates a p element with a message to show when the history is empty
 * @returns HTMLParagraphElement
 */
function generateEmptyDataMessage() {
  const p = document.createElement('p');
  p.classList.add('p-color--gray', 'fst-italic', 'mb-0', 'text-center');
  p.textContent = 'No hay información a mostrar';
  return p;
}

/**
 * Filters the history elements under these conditions:
 * - An element should have a message.
 * - An element should have a custom message (not custom messages are generated
 *   automatically by backend library, and are not human readable).
 * @param {*} element
 * @returns
 */
function removeTrashHistoryElement(element) {
  return element.message && element.custom_message;
}

/**
 * Generates the HTML to show the data received from fetchActivityUploadHistoryData
 * @param {array object} data - Array received by calling fetchActivityUploadHistoryData
 * @returns HTMLElement
 */
function generateDataElements(data) {
  const container = document.createElement('div');
  const dataToRender = data.filter(removeTrashHistoryElement);

  dataToRender.forEach((d) => {
    const dataWrapper = document.createElement('div');
    dataWrapper.classList.add('py-3', 'border-bottom');
    if (d.created_at) {
      dataWrapper.appendChild(getDateElement(d.created_at));
    }
    if (d.message) {
      dataWrapper.appendChild(getMessageElement(d.message));
    }
    if (d.error_file_url) {
      dataWrapper.appendChild(getErrorLinkElement(d.error_file_url));
    }
    container.appendChild(dataWrapper);
  });

  return container;
}

/**
 * Renders the data in a human readable format or an error message in case the data is empty
 * @param {HTMLElement} container - The element in which the data will be rendered
 * @param {array object} data - data response from fetchActivityUploadHistoryData
 */
function renderActivityUploadHistoryData(container, data) {
  if (!data.length) {
    container.appendChild(generateEmptyDataMessage());
  } else {
    container.appendChild(generateDataElements(data));
    addlinks();
  }
}

/**
 * Fetch history data for an specific upload selected by upload id.
 * @param {string} uploadId - The id of the upload whose history will be fetched
 * @returns {array object} - An array of objects which contains the history data to be rendered
 */
async function fetchActivityUploadHistoryData(uploadId) {
  const res = await fetch(Routes.history_api_activity_upload_path(uploadId));
  const data = await res.json();
  return data;
}

function addlinks() {
  const pElementsWithSuccesfullCreatedMessage = document.querySelectorAll('#upload_was_crated_succefull');
  const originalExcelUploadedLink = document.querySelectorAll('#user_template_link');
  const downloadIcon = document.createElement('span');
  downloadIcon.textContent = 'download';
  downloadIcon.classList.add('uc-icon', 'material-icons', 'm-0');
  downloadIcon.ariaHidden = 'true';
  var counter =0;
  for (const paragraphWhitSuccesfullMessage of pElementsWithSuccesfullCreatedMessage) {
    const downloadLink = originalExcelUploadedLink[counter]
    if (!downloadLink.dataset.error){
      downloadLink.classList.add('uc-btn', 'btn-inline', 'fw-medium');
      downloadLink.classList.remove('d-none');

      downloadLink.appendChild(downloadIcon.cloneNode(true));
    }
    const emptyDiv = document.createElement('div');
    emptyDiv.appendChild(downloadLink)
    paragraphWhitSuccesfullMessage.appendChild(emptyDiv);
    counter++;
  }
}
/**
 * Handles the fetch and render of the history.
 * @param {HTMLElement} container - the history container
 */
async function handleActivityUploadHistory(container) {
  const { uploadId } = container.dataset;

  if (!uploadId) {
    throw new Error('Upload Id was not found');
  }

  const data = await fetchActivityUploadHistoryData(uploadId);
  renderActivityUploadHistoryData(container, data);
}

export default function initActivityUploadHistory() {
  const historyContainer = document.getElementById('activityUploadHistory');
  if (!historyContainer) return;

  handleActivityUploadHistory(historyContainer).catch((err) => {
    console.error(err);
    // TODO: Add alert error handling
  });
}
