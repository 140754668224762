// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Style
import '../stylesheets/index.scss';

// Vendor
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'select2/dist/js/select2';
import 'flatpickr'; // Datepicker

// Custom JS
import initAlertClose from '../behaviours/alerts';
import initInputDate from '../behaviours/input-date';
import initRowLink from '../behaviours/row-link';
import initButtonsForms from '../behaviours/form-cards';
import initExternalReferences from '../behaviours/external-references';
import initResetButton from '../behaviours/reset-button';
import initCustomSummary from '../behaviours/custom-summary';
import initChangeTemplateDownloadLink from '../behaviours/change-template-download-link';
import initSwitchDoubleInputs from '../behaviours/form-switch-double';
import initCustomFileInput from '../behaviours/custom-file-input';
import initInternalReferences from '../behaviours/internal-references';
import initMultipleInputs from '../behaviours/multiple-input';
import initUserDetailsForm from '../behaviours/form-user-details';
import initExternalInformation from '../behaviours/external-information';
import initCustomVisualizationDateInputs from '../behaviours/custom-visualization-date';
import initActivityTemplateVisualizationFormEvents from '../behaviours/activity-template-visualization/form-activity-template-visualization';
import initStrategicTagCheckbox from '../behaviours/activity/strategic-tag-checkbox';
import initStrategicGuideButtonModal from '../behaviours/activity/strategic-guide-modal';
import initStrategicAxisSwitch from '../behaviours/tag-form/activity-template-tag-form';
import initActivityTemplateVisualizationFormDragAndDrop from '../behaviours/activity-template-visualization/form-activity-template-visualization-drag-and-drop';
import initFormatVisualizationType from '../behaviours/format-visualization-type';
import initStepsForms from '../behaviours/steps-form';
import initDropzone from '../behaviours/dropzone/dropzone';
import initMultipleUploadForm from '../behaviours/activity-upload/multiple-upload-form';
import initActivityUploadHistory from '../behaviours/activity-upload/history';
import initSingleUploadForm from '../behaviours/activity-upload/single-upload-form';


// Plugins
import initSelect2 from '../plugins/select2';
import { initPopover, initTooltip } from '../plugins/bootstrap';
import initTrimText from '../behaviours/trim-text';
import initShowMoreTogglers from '../behaviours/show-more-toggler';

Rails.start();
ActiveStorage.start();

window.addEventListener('load', () => {
  initStepsForms();
  initDropzone();
  initAlertClose();
  initInputDate();
  initRowLink();
  initButtonsForms();
  initExternalReferences();
  initResetButton();
  initSelect2();
  initPopover();
  initTooltip();
  initCustomSummary();
  initChangeTemplateDownloadLink();
  initSwitchDoubleInputs();
  initCustomFileInput();
  initInternalReferences();
  initMultipleInputs();
  initUserDetailsForm();
  initExternalInformation();
  initTrimText();
  initCustomVisualizationDateInputs();
  initActivityTemplateVisualizationFormEvents();
  initActivityTemplateVisualizationFormDragAndDrop();
  initStrategicAxisSwitch();
  initStrategicTagCheckbox();
  initStrategicGuideButtonModal();
  initFormatVisualizationType();
  initShowMoreTogglers();
  initMultipleUploadForm();
  initActivityUploadHistory();
  initSingleUploadForm();
});

import "controllers"
