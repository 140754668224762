import {
  Controller
} from "stimulus";
export default class extends Controller {
  static targets = ["select", "div", "paragraph", "selected_option","select_acdemic"]


  connect() {
    if (this.selected_optionTargets.length>0) {
      apply_selection(this.selectTargets[0], this.selected_optionTargets[0].value);
    }
    $(this.selectTargets).on('select2:select', function () {
      dispatchChangeEvent(this)
    });
  }

  ownershipStatusChange(event) {

    const strategies = {
      'automatic': hiddenInputUnit,
      'academic': hiddenInputUnit,
      'data_manager': showInputUnit
    }

    strategies[event.target.value]()
  }

 

  



  showProcessingDiv() {
      var selectedOptions = [];
      for (var i = 0; i < this.select_acdemicTarget.options.length; i++) {
          var option = this.select_acdemicTarget.options[i];
          if (option.selected) {
              selectedOptions.push(option.value);
          }
      };

      var notInTheSelect = 0;
      for (var x of selectedOptions) {
          if (x.toString().startsWith("id")) {
            notInTheSelect++;

          }
      }
      
      if (notInTheSelect > 0) {
          this.paragraphTarget.innerHTML = "Se ingresaron " + notInTheSelect + " usuarios que no son parte de " +
              "la base de datos de portafolio por lo que se consultaran con la base de datos historica de academicos" +
              ", el proceso tardara un poco mas de lo normal";
          this.divTarget.classList.remove('d-none');
      }

  }
}
function dispatchChangeEvent(element) {
  let event = new Event('change', { bubbles: true })
  element.dispatchEvent(event);

}

function apply_selection(select,value) {
    
  if (select) {
    // Iterar sobre las opciones del select
    for (let option of select.options) {
      if (option.value === value) {
        option.selected = true;
        // Disparar el evento 'change' en el select para notificar otros listeners
        select.dispatchEvent(new Event('change'));
        break;
      }
    }
  }

}

function hiddenInputUnit(){
  const div = document.getElementById("inputUnit");
  div.classList.add("d-none");
  const inputs = div.querySelectorAll("select");
  inputs.forEach(input => {
    input.removeAttribute("required");
    input.value = null;
  });
  inputAcademic(true)
}

function showInputUnit(){
  const div = document.getElementById("inputUnit");
  div.classList.remove("d-none");
  const inputs = div.querySelectorAll("select");
  inputs.forEach(input => {
    input.setAttribute("required", true);
  });
  inputAcademic(false)
}

function inputAcademic(required){
  const inputAcademics = document.getElementById("activity_upload_academics");

  if (inputAcademics){
  if(required === true){
    inputAcademics.setAttribute("required", true);
  } else {
    inputAcademics.removeAttribute("required");
  }
}

}
