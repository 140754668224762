import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.scrollToElement();
  }

  scrollToElement() {
    const urlParams = new URLSearchParams(window.location.search);
    const expandGroup = urlParams.get('expand_group');
    const expandKind = urlParams.get('expand_kind');
    const expandTemplate = urlParams.get('template_id');
    let elementId;

    if (expandTemplate) {
      elementId = `template_id_${expandTemplate}_card`;
    } else if (expandGroup) {
      elementId = `group_id_${expandGroup}_card`;
    } else if (expandKind) {
      elementId = `kind_id_${expandKind}_card`;
    }

    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  }
}
