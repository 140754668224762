import Sortable from 'sortablejs';

function reindex(itemFrom) {
  const container = itemFrom.parentNode;
  const { sortClass } = itemFrom.dataset;

  Array.from(container.getElementsByClassName(sortClass)).forEach((input, index) => {
    // Note: elements returned by `getElementsByClassName` effectively are in correct order (https://stackoverflow.com/questions/35525811/order-of-elements-in-document-getelementsbyclassname-array)
    input.value = index;
  });
}

function createSortables(cardContext) {
  let fieldsList = null;

  if (cardContext) {
    fieldsList = cardContext.getElementsByClassName('fields-list');
  } else {
    fieldsList = document.getElementsByClassName('fields-list');
  }
  if (!fieldsList) return;

  Array.from(fieldsList).forEach((list) => {
    Sortable.create(list, {
      group: list.id,
      handle: '.drag-indicator',
      onUpdate: (e) => reindex(e.item)
    });
  });
}

export default function initActivityTemplateVisualizationFormDragAndDrop(cardContext) {
  createSortables(cardContext);
}
