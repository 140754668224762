import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import * as Routes from '../routes.js.erb';
import { initCloseButton, autoCloseAlerts } from './alerts';

const INFORMATION_ROUTES = {
  'personal-data': Routes.api_personal_data_path,
  'address-data': Routes.api_address_data_path,
  'contact-data': Routes.api_contact_data_path,
  'uc-contact-data': Routes.api_uc_contact_data_path,
  'contract-data': Routes.api_contract_data_path,
  'decree-data': Routes.api_decree_data_path
};

function formatRowValue(data) {
  const value = data || '';
  return Array.isArray(value) && value.length > 0 ? value[0][1] : value;
}

function generateDataOrigin(url, tooltip) {
  if (url) {
    return `
      <span>
        <a href="${url}" class="p-color--gray p-size--sm text-decoration-underline">
          Información externa a Portafolio Académico
        </a>
      ${tooltip}
      </span>
     `;
  }
  return `
    <div class="p-color--gray p-size--sm">
      Información externa a Portafolio Académico ${tooltip}
    </div>
  `;
}

function generateInputTooltip(inputLabel) {
  const textToShow = "El equipo responsable de este dato utiliza un sistema distinto a Portafolio Académico para su administración. Si existe un error, por favor indicarlo a la mesa de ayuda de Portafolio Académico mediante el botón 'Solicitar corrección'. La mesa de ayuda trabajará de forma interna la solicitud junto al equipo correspondiente, solicitará mas antecedentes en caso de ser necesario e informará del avance.";
  const feedbackPath = Routes.new_feedback_path({ breadcrumb: `Mi información > Campo: ${inputLabel}` });

  return `
    <span
      aria-hidden="true"
      class="uc-icon p-color--gray icon-size--sm"
      role="button"
      tabindex="0"
      title="
        Este dato proviene de otro sistema
        <div class='uc-text-divider divider-primary mt-2'></div>
      "
      aria-label="${textToShow}"
      data-bs-toggle="popover"
      data-bs-placement="bottom"
      data-bs-trigger="click"
      data-bs-container="body"
      data-bs-html="true"
      data-bs-custom-class="rounded"
      data-bs-content="
        <div class='d-flex flex-column'>
          <p>${textToShow}</p>
          <a class='d-flex align-items-center text-decoration-none' href='${feedbackPath}'>Solicitar corrección <span class='uc-icon ml-4'>edit</span></a>
        </div>
      "
    >info</span>
  `;
}

function getInputTemplate(inputValue) {
  return `<input disabled value="${inputValue}" class="uc-input-style" />`;
}

function generateDataRows(data, showFormFields) {
  const dataRowTemplate = (rowData) => {
    const tooltip = showFormFields ? generateInputTooltip(rowData.label) : '';
    return (`
      <div class="row py-16 px-16 align-items-center">
        <div class="col-lg-4">
          <div class="p-color--gray py-12">
            ${rowData.label}
          </div>
          <div class="d-flex">
            ${rowData.external_source ? generateDataOrigin(rowData.source_url, tooltip) : ''}
          </div>
        </div>

        <div class="col-lg" id="activity_field_">
          <div>
          ${showFormFields ? getInputTemplate(formatRowValue(rowData.value)) : formatRowValue(rowData.value)}
          </div>
        </div>
      </div>
      <hr class="my-0" />
  `);
  };

  return data.map(dataRowTemplate).join('');
}

function generatePagination(pagination) {
  return pagination.html;
}

function generateTableView(data, pagination) {
  return `
    <div class="table-responsive">
      <table class="uc-table w-100">
        <thead>
          <tr>
            <th class="w-30">Categoría Académica</th>
            <th class="w-30">Fecha Inicio</th>
            <th class="w-30">Fecha Término</th>
            <th class="w-30">Planta Académica</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          ${data.map((row) => `<tr>
            <td class="p-color--gray">${row.decree_description}</td>
            <td>${row.decree_start_date}</td>
            <td>${row.decree_end_date}</td>
            <td class="p-color--gray table-main-text-small">${row.decree_academic_plant}</td>
            <td class="text-end text-nowrap">
              <a href="${Routes.academic_category_path(row.decree_application_number)}" class="uc-btn btn-inline">
                Ver detalle
                <span class="uc-icon" aria-hidden="true">
                  chevron_right
                </span>
              </a>
            </td>
          </tr>`).join('')}
        </tbody>
      </table>
    </div>
    ${generatePagination(pagination)}
  `;
}

function hideTooltip(tooltip) {
  const tooltipBootstrap = bootstrap.Popover.getInstance(tooltip);
  tooltipBootstrap.hide();
}

function initTooltipsInsideContainer(container) {
  const tooltips = container.querySelectorAll('[data-bs-toggle="popover"]');
  tooltips.forEach((currentTootip) => {
    // eslint-disable-next-line no-new
    new bootstrap.Popover(currentTootip);

    currentTootip.addEventListener('shown.bs.popover', () => {
      const otherTooltips = Array
        .from(tooltips)
        .filter((tooltip) => !tooltip.isSameNode(currentTootip));

      otherTooltips.forEach(hideTooltip);
    });
  });
}

function fillExternalInformationContainer(container, data) {
  const isTableView = container.dataset.tableView;

  if (isTableView) {
    container.innerHTML = generateTableView(data.data, data.pagination);
  } else {
    const isFormView = container.dataset.formView;
    container.insertAdjacentHTML('beforeend', generateDataRows(data.data, isFormView));
    initTooltipsInsideContainer(container);
  }
}

function showExternalInformationError(container) {
  container.innerHTML = `
    <div class="uc-alert warning mb-12 2">
      <div class="uc-alert_content">
        <i class="uc-icon icon-size--sm">warning</i>
        <span class="p p-size--sm bold ml-8">
          Error buscando la información externa.
        </span>
      </div>
      <div class="uc-alert_close d-flex align-items-center">
        <button aria-label="Cerrar" class="uc-alert--close uc-btn btn-inline">
          <i class="uc-icon">close</i>
        </button>
      </div>
    </div>`;

  autoCloseAlerts();
  initCloseButton();
}

function showLoadingSpinner(container) {
  container.innerHTML = `
    <div class="spinner-border text-primary d-block mx-auto" role="status">
      <span class="invisible">Cargando...</span>
    </div>`;
}

function removeLoadingSpinner(container) {
  container.querySelector('.spinner-border')?.remove();
}

async function fetchExternalInformation(url) {
  const response = await fetch(url);
  const data = await response.json();

  return {
    ok: response.ok,
    data
  };
}

function addPaginationEvents(container) {
  const $pagination = $(container).find('[data-external-pagination]');
  if ($pagination.length > 0) {
    $pagination.find('button').on('click', async (e) => {
      showLoadingSpinner(container);
      const data = await fetchExternalInformation(e.target.dataset.apiLink);
      removeLoadingSpinner(container);
      fillExternalInformationContainer(container, data.data);
      addPaginationEvents(container);
    });
  }
}

async function handleExternalInformationContainer(container) {
  const dataToFerch = container.dataset.externalInformation;
  const url = INFORMATION_ROUTES[dataToFerch]();

  showLoadingSpinner(container);

  try {
    const externalInformation = await fetchExternalInformation(url);

    if (!externalInformation.ok) {
      showExternalInformationError(container);
    }

    if (externalInformation.data) {
      fillExternalInformationContainer(container, externalInformation.data);
      addPaginationEvents(container);
    }
  } catch (e) {
    showExternalInformationError(container);
  }

  removeLoadingSpinner(container);
}

const initHideTooltipButtons = () => {
  const hideTooltipsButtons = document.querySelectorAll('[data-hide-tooltip]');
  if (hideTooltipsButtons) {
    hideTooltipsButtons.forEach((button) => {
      button.addEventListener('click', () => {
        const tooltips = document.querySelectorAll('[data-bs-toggle="popover"]');
        tooltips.forEach((tooltip) => {
          hideTooltip(tooltip);
        });
      });
    });
  }
};

export default function initExternalInformation() {
  const externalInformationContainers = document.querySelectorAll('[data-external-information]');
  externalInformationContainers.forEach(handleExternalInformationContainer);
  initHideTooltipButtons();
}
