const alertTimeout = 10_000;

export const initCloseButton = () => {
  const ucButtonsClose = [...document.getElementsByClassName('uc-alert--close')];
  if (ucButtonsClose) {
    ucButtonsClose.forEach((button) => {
      button.addEventListener('click', (e) => {
        const alert = e.target.closest('.uc-alert');

        if (alert.parentNode) {
          alert.parentNode.removeChild(alert);
        }
      });
    });
  }
};

export const autoCloseAlerts = () => {
  const alerts = document.querySelectorAll('.uc-alert');
  setTimeout(() => {
    if (alerts) {
      alerts.forEach((alert) => {
        alert.parentNode?.removeChild(alert);
      });
    }
  }, alertTimeout);
};

export default function initAlertClose() {
  let dismissAlertTimeoutId = null;

  window.showWarningAlert = (msg) => {
    const container = document.getElementById('js-alert-container');
    container.innerHTML = `
      <div class="uc-alert warning mb-12 1">
        <div class="uc-alert_content">
          <i class="uc-icon icon-size--sm">warning</i>
          <span id="js-alert-msg" class="p p-size--sm bold ml-8"></span>
        </div>
        <div class="uc-alert_close d-flex align-items-center">
          <button aria-label="Cerrar" class="uc-alert--close uc-btn btn-inline">
            <i class="uc-icon">close</i>
          </button>
        </div>
      </div>
    `;

    document.getElementById('js-alert-msg').textContent = msg;

    initCloseButton();

    if (dismissAlertTimeoutId != null) {
      clearTimeout(dismissAlertTimeoutId);
    }

    dismissAlertTimeoutId = setTimeout(() => {
      const ucAlert = [...container.getElementsByClassName('uc-alert')];

      if (ucAlert) {
        ucAlert.forEach((alert) => {
          alert.parentNode.removeChild(alert);
        });
      }
    }, alertTimeout);
  };

  autoCloseAlerts();
  initCloseButton();
}
