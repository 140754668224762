const enableFormSubmit = (form) => {
  const submitButton = form.querySelector('[data-form-submit-button]');
  if (submitButton) {
    submitButton.disabled = false;
  }
};

const disableFormSubmit = (form) => {
  const submitButton = form.querySelector('[data-form-submit-button]');
  if (submitButton) {
    submitButton.disabled = true;
  }
};

const getFormRequiredFields = (form) => form.querySelectorAll('input[required], select[required], textarea[required]');

const allFormRequiredValuesAreSet = (form) => {
  const requiredFields = getFormRequiredFields(form);
  return Array.from(requiredFields).every((field) => Boolean(field.value));
};

const handleRequiredFieldChange = (e) => {
  const { value } = e.target;
  const form = e.target.closest('form');

  if (value && allFormRequiredValuesAreSet(form)) {
    enableFormSubmit(form);
  } else {
    disableFormSubmit(form);
  }
};

const handleSecondStepFormInitialState = (form) => {
  const requiredFields = getFormRequiredFields(form);
  if (requiredFields.length === 0 || allFormRequiredValuesAreSet(form)) {
    enableFormSubmit(form);
  } else {
    disableFormSubmit(form);
  }

  requiredFields.forEach((field) => field.addEventListener('input', handleRequiredFieldChange));
  requiredFields.forEach((field) => {
    if (field.tagName.toLowerCase() === 'select') {
      $(field).on('change', handleRequiredFieldChange);
    } else {
      field.addEventListener('change', handleRequiredFieldChange);
    }
  });
};

const handleSecondStepDraftButton = (form) => {
  const button = form.querySelector('[data-draft-single-button]');
  if (button) {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      const draftPath = button.dataset.draftSingleButton;
      form.action = draftPath;
      form.submit();
    });
  }
};

export default function initSingleUploadForm() {
  const secondStepForm = document.getElementById('singleActivityUploadFormSecondStep');
  if (secondStepForm) {
    handleSecondStepFormInitialState(secondStepForm);
    handleSecondStepDraftButton(secondStepForm);
  }
}
