import { Controller } from "stimulus";
 
export default class extends Controller {
  static targets = ["switch", "description"]
  cambio() {
    if (this.switchTarget.checked) {
      this.descriptionTarget.classList.remove("collapse");
      this.descriptionTarget.classList.add("show");
    } else {
      this.descriptionTarget.classList.remove("show");
      this.descriptionTarget.classList.add("collapse");
    
    }
  }
}