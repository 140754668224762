export const MODAL_ACTIVITY_TEMPLATE = 'MODAL_ACTIVITY_TEMPLATE';
export const MODAL_STRATEGIC_TAG = 'MODAL_STRATEGIC_TAG';

const getPrimaryButton = () => {
  const primaryButton = document.getElementById('tag-modal-primary-button');
  return primaryButton;
};

export const replacePrimaryButton = (callback) => {
  const primaryButton = getPrimaryButton();
  const newPrimaryButton = primaryButton.cloneNode(true);
  primaryButton.replaceWith(newPrimaryButton);
  newPrimaryButton.addEventListener('click', callback);
};

export const setModal = (modalCase) => {
  const title = document.getElementById('tag-modal-title');
  const text = document.getElementById('tag-modal-text');

  if (modalCase === MODAL_ACTIVITY_TEMPLATE) {
    title.textContent = 'Deshabilitar subtipo';
    text.textContent = '¿Está seguro que quiere deshabilitar la etiqueta en este subtipo? Si lo hace, la guía será eliminada.';
  } else {
    title.textContent = 'Deshabilitar eje estratégico';
    text.textContent = '¿Está seguro que quiere deshabilitar el eje estratégico de la etiqueta? Al deshabilitar, el académico dejará de ver la etiqueta en la sección de ejes y la verá en la de etiquetas.';
  }
};
