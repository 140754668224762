import { showAlert } from '../fixed-alerts';

export const DROPZONE_STATE = {
  FILE_DETAILS: 'FILE_DETAILS',
  UPLOAD_FILE: 'UPLOAD_FILE'
};

const SECTION_CLASSES = {
  FILE_DETAILS_SECTION: 'file-details-section',
  UPLOAD_FILE_SECTION: 'upload-file-section',
};

const DROPZONE_STATE_CLASSES = {
  UPLOAD_FILE: 'upload-file',
  FILE_DETAILS: 'file-details',
};

const BUTTON_DETAILS_ACTION_CLASS = 'button-details-action';

const convertSizeToKb = (size) => `${(size / 1024).toFixed(2)} kb`;

const getDownloadLink = (file) => {
  const downloadAnchor = document.createElement('a');
  downloadAnchor.className = 'uc-btn btn-inline text-weight--medium';
  downloadAnchor.classList.add(BUTTON_DETAILS_ACTION_CLASS);
  downloadAnchor.setAttribute('href', window.URL.createObjectURL(file));
  downloadAnchor.setAttribute('download', file.name);
  downloadAnchor.innerHTML = 'Descargar<i class="uc-icon ms-1">download</i>';

  return downloadAnchor;
};

const getDeleteFileButton = () => {
  const deleteButton = document.createElement('button');
  deleteButton.className = 'p-0 text-primary uc-btn btn-inline text-weight--medium';
  deleteButton.classList.add(BUTTON_DETAILS_ACTION_CLASS);
  deleteButton.type = 'button';

  const icon = document.createElement('i');
  icon.className = 'uc-icon ms-1';
  icon.innerHTML = '<p class="text-size--sm mb-0">delete_outline</p>';

  deleteButton.innerHTML = `Eliminar${icon.outerHTML}`;

  return deleteButton;
};

const showFileDetailsSection = (dropzone, file) => {
  const fileNameElement = dropzone.querySelector('.file-name');
  const fileSizeElement = dropzone.querySelector('.file-size');

  fileNameElement.textContent = file.name;
  if (file.size) {
    fileSizeElement.textContent = convertSizeToKb(file.size);
  }

  dropzone.classList.replace(
    DROPZONE_STATE_CLASSES.UPLOAD_FILE,
    DROPZONE_STATE_CLASSES.FILE_DETAILS
  );
};

const showUploadFileSection = (dropzone) => {
  dropzone.classList.replace(
    DROPZONE_STATE_CLASSES.FILE_DETAILS,
    DROPZONE_STATE_CLASSES.UPLOAD_FILE
  );
};

const getNewActionDetailsButton = (dropzone, file, fileActionButton, isDownload) => {
  const currentButton = fileActionButton.querySelector(`.${BUTTON_DETAILS_ACTION_CLASS}`);
  if (currentButton) {
    currentButton.remove();
  }

  const buttonToShow = isDownload ? getDownloadLink(file) : getDeleteFileButton();
  return buttonToShow;
};

export const setFileState = (state, dropzone, file) => {
  if (DROPZONE_STATE.FILE_DETAILS === state) {
    const fileActionButton = dropzone.querySelector('.file-action-button');
    const isDownload = fileActionButton.classList.contains('download');
    showFileDetailsSection(dropzone, file);

    const newButtonToShow = getNewActionDetailsButton(dropzone, file, fileActionButton, isDownload);

    if (!isDownload) {
      newButtonToShow.addEventListener('click', () => setFileState(DROPZONE_STATE.UPLOAD_FILE, dropzone));
    }

    fileActionButton.appendChild(newButtonToShow);
  } else {
    const input = dropzone.querySelector('input');
    const changeEvent = new Event('change', { cancelable: true });
    input.value = '';
    input.dispatchEvent(changeEvent);
    showUploadFileSection(dropzone);
  }
};

const handleChangeFile = (e) => {
  const { target, cancelable } = e;

  if (!cancelable) {
    // TODO: Handle multiple files
    const file = target.files[0];

    const newState = file ? DROPZONE_STATE.FILE_DETAILS : DROPZONE_STATE.UPLOAD_FILE;

    const dropzone = target.parentNode;
    setFileState(newState, dropzone, file);
  }
};

const handleClickDropzone = (e, fileInput) => {
  fileInput.click();

  // TODO: Handle file in form
};

const handleDragAndDropEvents = (dropzone) => {
  const uploadFileSection = dropzone.querySelector(`.${SECTION_CLASSES.UPLOAD_FILE_SECTION}`);

  uploadFileSection.addEventListener('dragover', (e) => {
    e.preventDefault();
    uploadFileSection.classList.add('drag-over');
  });

  uploadFileSection.addEventListener('dragleave', (e) => {
    e.preventDefault();
    uploadFileSection.classList.remove('drag-over');
  });

  uploadFileSection.addEventListener('drop', (e) => {
    e.preventDefault();
    const input = dropzone.querySelector('input');
    uploadFileSection.classList.remove('drag-over');
    const file = e.dataTransfer.files[0];

    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      input.files = e.dataTransfer.files;
      setFileState(DROPZONE_STATE.FILE_DETAILS, dropzone, file);
      const event = new Event('change', { bubbles: true });
      input.dispatchEvent(event);
    } else {
      const { accept } = input;
      showAlert('error', 'cancel', `Solo se permiten archivos con extensión ${accept}`);
    }
  });
};

function handleDropzoneInitialState(dropzone) {
  const fileDetailsVisible = dropzone.classList.contains(DROPZONE_STATE_CLASSES.FILE_DETAILS);
  const fileName = dropzone.querySelector('.file-name')?.textContent;

  if (fileDetailsVisible && fileName) {
    setFileState(DROPZONE_STATE.FILE_DETAILS, dropzone, new File([''], fileName));
    // HTML doesn't allow to set input files value, so we use a dataset property
    // as a placeholder to detect if the input file inside the dropzone already has
    // a file set.
    dropzone.querySelector('input[type="file"]').dataset.placeholderValue = fileName;
  }
}

export default function initDropzone() {
  // TODO: Handle disabled state
  const dropzones = document.querySelectorAll('.dropzone');

  dropzones.forEach((dropzone) => {
    const fileInput = dropzone.querySelector('input');
    fileInput.addEventListener('change', handleChangeFile);
    const uploadFileSection = dropzone.querySelector(`.${SECTION_CLASSES.UPLOAD_FILE_SECTION}`);
    uploadFileSection.addEventListener('click', (e) => handleClickDropzone(e, fileInput));

    handleDragAndDropEvents(dropzone);
    handleDropzoneInitialState(dropzone);
  });
}
