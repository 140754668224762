import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"];

  static values = {
    fields: Object,
    lang: Object
  }

  connect() {
    $(this.selectTargets).on('select2:select', function () {
      dispatchChangeEvent(this)
    });
  }

  updateField(event) {
    var value = event.target.value
    var kind = this.fieldsValue[value]["kind"]
    var namespace = this.fieldsValue[value]["namespace"]
    var kind_selector = document.getElementById("activity_template_choice_type")
    var namespace_selector = document.getElementById("activity_template_namespace_id")
    

    var strategy = {
      "string": ["choices", "dynamic_choices"],
      "choices": ["string", "dynamic_choices"],
      "dynamic_choices": ["string", "choices"],
    }

    kind_selector.innerHTML = "";
    strategy[kind].forEach(value => {
      var label = `${this.langValue[kind]} ► ${this.langValue[value]}`
      addOption(kind_selector, value, label)
    });

    namespace_selector.value = namespace

    dispatchChangeEvent(kind_selector)
    dispatchChangeEvent(namespace_selector)
  }

  updateKind(event) {
    var value = event.target.value
    var namespace_row = document.getElementById("namespace_id_row")
    var namespace_selector = document.getElementById("activity_template_namespace_id")

    var strategy = {
      "string": false,
      "choices": true,
      "dynamic_choices": true,
    }

    var visible = strategy[value];
    if (visible == undefined) visible = false;

    if (visible) {
      namespace_row.classList.remove("d-none");
      namespace_selector.setAttribute('required', 'required');
    } else {
      namespace_row.classList.add("d-none");
      namespace_selector.removeAttribute('required');
    }
  }
}

function dispatchChangeEvent(element) {
  let event = new Event('change', { bubbles: true })
  element.dispatchEvent(event);
}

function addOption(element, value, label){
  element.innerHTML += `<option value="${value}">${label}</option>`
}
