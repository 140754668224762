import { initCloseButton, autoCloseAlerts } from '../alerts';

let tagId = null;
let checkboxElement = null;

// TODO: Handle reason text in fetch when backend is ready to receive it
let reasonText = '';

const ACTIVITY_ID = window.location.pathname.split('/')[2];
const ACTIVITY_INSTANCE_ENDPOINT = '/api/activity_instances';

const showAlert = (type, icon, msg) => {
  const alert = document.createElement('div');
  alert.innerHTML = `
    <div class="uc-alert ${type} mb-3">
      <div class="uc-alert_content">
        <i class="uc-icon icon-size--sm"  aria-hidden="true">${icon}</i>
        <span id="js-alert-msg" class="p p-size--sm bold ml-8">
          ${msg}
        </span>
      </div>
      <div class="uc-alert_close d-flex align-items-center">
        <button aria-label="Cerrar" class="uc-alert--close uc-btn btn-inline">
          <i class="uc-icon" aria-hidden="true">close</i>
        </button>
      </div>
    </div>
  `;

  const wrapperAlerts = document.getElementById('js-alert-container');
  wrapperAlerts.appendChild(alert);
  autoCloseAlerts();
  initCloseButton();
};

const fetchAssignStrategicTagToActivity = async () => {
  const { checked } = checkboxElement;
  const response = await fetch(`${ACTIVITY_INSTANCE_ENDPOINT}/${ACTIVITY_ID}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      taggings_attributes: [
        {
          tag_id: tagId,
          _destroy: checked
        }
      ]
    })
  }).then((res) => res);

  if (response.status === 200) {
    checkboxElement.checked = !checked;
    showAlert('success', 'check_circle', 'Su respuesta ha sido enviada con éxito. Esta se encontrará en el historial de esta actividad.');
  } else {
    const { error } = await response.json();
    checkboxElement.checked = checked;
    showAlert('error', 'cancel', `Ha ocurrido un error al tratar de ${checked ? 'desvincular' : 'vincular'} el eje estratégico ${checked ? 'de' : 'a'} la actividad: ${error}`);
  }
};

const setModal = (newTagId, newTagName, checkbox) => {
  tagId = newTagId;
  checkboxElement = checkbox;

  const { checked } = checkboxElement;
  checkboxElement.checked = !checked;

  const primaryButton = document.getElementById('strategic-modal-primary-button');
  const secondaryButton = document.getElementById('strategic-modal-secondary-button');
  const title = document.getElementById('strategic-modal-title');
  const { dataset: { isOperator } } = title;
  const icon = document.getElementById('strategic-modal-icon');
  const modalCard = document.getElementById('strategic-modal-card');
  const text = document.getElementById('strategic-modal-text');
  const wrapperTextarea = document.getElementById('strategic-modal-wrapper-textarea');
  const textarea = document.getElementById('strategic-modal-textarea');
  textarea.value = '';

  if (checked) {
    primaryButton.textContent = 'Enviar';
    secondaryButton.textContent = 'Cerrar';
    title.textContent = 'Marca de eje estratégico';
    text.textContent = `Se ha marcado esta actividad con el eje estratégico ${newTagName}. Opcionalmente puede indicar el porqué lo ha hecho en el recuadro a continuación. Esta explicación permite entender la forma en que la actividad se relaciona al eje y cómo aporta a él.`;
    wrapperTextarea.classList.replace('d-none', 'd-block');
    text.classList.add('text-weight--bold');
    icon.classList.replace('d-inline', 'd-none');
    modalCard.classList.replace('warning', 'info');
  } else {
    primaryButton.textContent = isOperator ? 'Deshabilitar' : 'Desmarcar';
    secondaryButton.textContent = 'Cancelar';
    title.textContent = isOperator ? 'Deshabilitar Internacionalización' : 'Desmarcar Internacionalización';
    wrapperTextarea.classList.replace('d-block', 'd-none');
    text.textContent = `¿Está seguro que quiere ${isOperator ? 'deshabilitar' : 'desmarcar'} el eje estratégico?`;
    text.classList.remove('text-weight--bold');
    modalCard.classList.replace('info', 'warning');
    icon.classList.replace('d-none', 'd-inline');
  }
};

export default function initStrategicTagCheckbox() {
  const strategicCheckboxes = document.getElementsByClassName('strategic-checkbox');

  const primaryButton = document.getElementById('strategic-modal-primary-button');
  if (primaryButton) {
    primaryButton.addEventListener('click', () => fetchAssignStrategicTagToActivity());
  }

  const reasonTextarea = document.getElementById('strategic-modal-textarea');
  if (reasonTextarea) {
    reasonTextarea.addEventListener('change', (e) => { reasonText = e.target.value; });
  }

  Array.from(strategicCheckboxes).forEach((checkbox) => {
    const { dataset: { tagId: newTagId, tagName: newTagName } } = checkbox;
    checkbox.addEventListener('change', () => setModal(newTagId, newTagName, checkbox));
  });
}
