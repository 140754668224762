function handleUserAcademicUnit() {
  const $form = $('#adminUserDetailsForm');
  const $userRoleInput = $('#user_role_ids');
  const dataManagerRolId = $userRoleInput.data('managerId');
  const adminRolId = $userRoleInput.data('adminId');

  if (
    $userRoleInput.val().includes(dataManagerRolId.toString())
    || $userRoleInput.val().includes(adminRolId.toString())
  ) {
    $form.find('#user_academic_unit_ids').removeAttr('disabled');
  } else {
    $form.find('#user_academic_unit_ids').attr('disabled', true);
  }
}

export default function initUserDetailsForm() {
  const $userRoleInput = $('#user_role_ids');
  $userRoleInput.on('change', handleUserAcademicUnit);
  if ($userRoleInput.length) {
    handleUserAcademicUnit();
  }
}
