import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';

const BUTTON_STRATEGIC_TOOLTIP_CLASS = 'button-strategic-tooltip';
const BUTTON_STRATEGIC_GUIDE_MODAL_CLASS = 'button-strategic-guide-modal';
const MODAL = 'strategic-modal-guide';

const closeOtherPopovers = (currentPopover, popovers) => {
  Array.from(popovers).forEach((otherPopover) => {
    if (otherPopover !== currentPopover) {
      const popoverInstance = bootstrap.Popover.getInstance(otherPopover);
      if (popoverInstance) {
        popoverInstance.hide();
      }
    }
  });
};

const initializeModalButton = (popover, guide) => {
  setTimeout(() => {
    const strategicButtonModal = document.querySelector(`.${BUTTON_STRATEGIC_GUIDE_MODAL_CLASS}`);
    strategicButtonModal.addEventListener('click', () => {
      popover.click();
      const modal = document.querySelector(`[data-modal='${MODAL}']`);
      const p = modal.querySelector('p');
      p.textContent = guide;
      modal.style = 'display: block';
    });
  }, 100);
};

const initializePopovers = () => {
  const popovers = document.getElementsByClassName(BUTTON_STRATEGIC_TOOLTIP_CLASS);

  Array.from(popovers).forEach((popover) => {
    const { dataset: { guide } } = popover;
    popover.addEventListener('show.bs.popover', () => {
      closeOtherPopovers(popover, popovers);
      initializeModalButton(popover, guide);
    });
  });
};

export default function initStrategicGuideButtonModal() {
  initializePopovers();
}
