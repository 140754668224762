import * as Routes from '../routes.js.erb';

function handleInternalReferenceSelect(select) {
  const fieldId = select.dataset.activityFieldId;
  $(select).select2({
    width: '100%',
    language: {
      searching: () => 'Buscando...'
    },
    ajax: {
      url: Routes.options_api_activity_field_path(fieldId),
      dataType: 'json',
      data: (params) => {
        const queryParams = {
          search_term: params.term,
          limit: 10
        };

        return queryParams;
      },
      processResults: (responseOptions) => {
        const optionsToShow = {
          results: []
        };
        responseOptions.forEach((option) => {
          optionsToShow.results.push({
            id: option.id,
            text: option.value
          });
        });

        return optionsToShow;
      }
    }
  });
}

export default function initInternalReferences() {
  const internalReferencesSelects = document.querySelectorAll('select[internal-reference="true"]');
  if (internalReferencesSelects) {
    internalReferencesSelects.forEach(handleInternalReferenceSelect);
  }
}
