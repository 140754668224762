function setSwitchDoubleLabelClickEvent(input) {
  const $parentContainer = $(input).parents('.form-switch-double');
  if ($parentContainer.length) {
    const $inactiveLabel = $parentContainer.find('.form-switch-inactive-text');
    const $activeLabel = $parentContainer.find('.form-switch-active-text');

    if ($inactiveLabel.length > 0) {
      $inactiveLabel.on('click', () => {
        if (input.checked && !input.disabled) $(input).prop('checked', false);
      });
    }

    if ($activeLabel.length > 0) {
      $activeLabel.on('click', () => {
        if (!input.checked && !input.disabled) $(input).prop('checked', true);
      });
    }
  }
}

export default function initSwitchDoubleInputs() {
  const switchDoubleInputs = document.querySelectorAll('.form-switch-double .form-check-input');
  switchDoubleInputs.forEach((input) => {
    setSwitchDoubleLabelClickEvent(input);
  });
}
