import { setFileState, DROPZONE_STATE } from '../dropzone/dropzone';
import { showAlert } from '../fixed-alerts';

/**
 * Fetch the tamplte file and handles errors
 * @param {string} url - URL of the file to fetch
 * @returns Blob
 */
const fetchFile = async (url) => {
  const res = await fetch(url);
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  const file = await res.blob();
  return file;
};

/**
 * Generate the link that points to the template to download
 * @param {string} link - The inital link of the template
 * @param {string} templateToDownload - The identifier of the specific template to download
 * @returns string - an URL with params
 */
const generateTemplateDownloadLinkURL = (link, templateToDownload) => {
  const url = new URL(link.href);
  const templateDownloadButtonSearchParams = new URLSearchParams(url);
  templateDownloadButtonSearchParams.set('activity_template', templateToDownload);
  url.search = templateDownloadButtonSearchParams.toString();
  link.href = url.href;
  return link.href;
};

/**
 * Fetch file data and handle form status while and after fetch.
 * @param {string} urlToFetch - The URL of the file to fetch
 * @param {HTLFormElement} form - The form that contains the file input
 * @returns Blob | null - File blob in case the call was successful, null in case it wasn't
 */
const handleFileDataFetchInForm = async (urlToFetch, form) => {
  const spinner = document.getElementById("spinnertemplate");
  const selectElement = document.getElementById('activity_template');
  const filename = document.getElementById('file_name').innerText;
  const selectedOption = selectElement.options[selectElement.selectedIndex];
  const selectedText = selectedOption.innerText;


if (selectedText!=filename){
   spinner.classList.remove('d-none');
   try {
    const fileBlob = await fetchFile(urlToFetch);
    spinner.classList.add('d-none');
    return fileBlob;
  } catch (error) {
    showAlert('error', 'cancel', 'Ocurrió un error al cargar la plantilla. Por favor intente de nuevo.');
    spinner.classList.add('d-none');
    console.error(error);
  }
}

 
 
  return null;
};

const handleChangeActivityTemplateSelect = async (
  activityTemplateSelect, templateDownloadButton
) => {
  const templateSelectValue = $(activityTemplateSelect).val();
  if (!templateSelectValue) {
    return;
  }

  const templateName = $(activityTemplateSelect).find('option:selected').text();
  const linkURL = generateTemplateDownloadLinkURL(templateDownloadButton, templateSelectValue);
  const fileBlob = await handleFileDataFetchInForm(linkURL, activityTemplateSelect.closest('form'));
  if (!fileBlob) {
    return;
  }
  fileBlob.name = templateName;
  const wrapperDropZone = templateDownloadButton.previousElementSibling;
  setFileState(DROPZONE_STATE.FILE_DETAILS, wrapperDropZone, fileBlob);
};

const handleDisablingSubmitButton = (e, submitButton) => {
  const { target } = e;
  if (target.value === '' && !target.dataset.placeholderValue) {
    submitButton.disabled = 'true';
  } else {
    submitButton.disabled = '';
  }
  target.removeAttribute('data-placeholder-value');
};

const initDraftButton = () => {
  const draftButton = document.querySelector('[data-form-draft-button]');

  if (draftButton) {
    const form = draftButton.closest('form');
    draftButton.addEventListener('click', (e) => {
      e.preventDefault();
      const statusInput = document.getElementsByName('activity_upload[status]');
      statusInput[0].value = 'draft';
      form.submit();
    });
  }
};

export default function initMultipleUploadForm() {
  initDraftButton();

  const templateDownloadButton = document.getElementById('templateDownloadLink');
  const filledTemplateInputFile = document.getElementById('filledTemplate');
  const button = document.getElementById('butonnextstep'); 
  const submitButton = document.querySelector('[data-form-submit-button]');

  if (templateDownloadButton) {
    const activityTemplateSelect = document.querySelector('select#activity_template');
    $(button).on('click', () => handleChangeActivityTemplateSelect(activityTemplateSelect, templateDownloadButton));
    $(activityTemplateSelect).trigger('change');
  }

  if (filledTemplateInputFile && submitButton) {
    $(filledTemplateInputFile).on('change', (e) => handleDisablingSubmitButton(e, submitButton));
    // Trigger change to handle file input initial state
    $(filledTemplateInputFile).trigger('change');
  }
}
