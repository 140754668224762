import tippy from 'tippy.js';

export default function initializeTooltip(element) {
  tippy(element, {
    theme: 'uc',
    arrow: true,
    arrowType: 'round',
    interactive: true,
  });
}
