const handleFormVisibility = (newCurrentStep, currentStepElement) => {
  const newCurrentStepElement = document.querySelector(`[data-step-form="${newCurrentStep}"]`);
  if (!newCurrentStepElement) return;

  currentStepElement.classList.replace('d-block', 'd-none');
  newCurrentStepElement.classList.replace('d-none', 'd-block');
};

const handleStyleOfSteps = (newCurrentStep) => {
  const steps = document.querySelectorAll('.uc-step');
  steps.forEach((stepElement) => {
    const { dataset: { step } } = stepElement;

    if (step === newCurrentStep) {
      stepElement.classList.remove('step-done');
      stepElement.classList.add('step-current');
    } else if (step < newCurrentStep) {
      stepElement.classList.add('step-done');
      stepElement.classList.remove('step-current');
    } else {
      stepElement.classList.remove('step-current');
    }
  });
};

const stepIsInsideRange = (stepNumber) => {
  const newCurrentStepElement = document.querySelector(`[data-step-form="${stepNumber}"]`);
  return stepNumber !== 0 && Boolean(newCurrentStepElement);
};

const handleChangeStep = (e) => {
  const { target, target: { dataset: { redirectToStep } } } = e;
  const form = target.closest('form');

  if (form.checkValidity()) {
    const stepWrapper = target.closest('[data-step-form]');
    if (stepIsInsideRange(redirectToStep)) {
      handleStyleOfSteps(redirectToStep);
      handleFormVisibility(redirectToStep, stepWrapper);
    }
  }
};

const isValidSubForm = (formInputs) => {
  let isValid = true;

  formInputs.forEach((formInput) => {
    const { required: isRequired, value } = formInput;
    if (isRequired && !value) {
      isValid = false;
    }
  });

  return isValid;
};

const validateNextStepButton = (form, isValidForm) => {
  const nextStepButton = form.querySelector('[data-next-step-button]');

  if (isValidForm) {
    nextStepButton.removeAttribute('disabled');
  } else {
    nextStepButton.disabled = true;
  }
};

const handleSubFormValidation = (subForm) => {
  const inputs = Array.from(subForm.querySelectorAll('input:not([type=hidden])'));
  const selects = Array.from(subForm.querySelectorAll('select'));

  inputs.forEach((input) => {
    input.addEventListener('input', () => {
      const isValid = isValidSubForm([...selects, ...inputs]);
      validateNextStepButton(subForm, isValid);
    });
  });

  selects.forEach((select) => {
    $(select).on('change', () => {
      isValidSubForm([...selects, ...inputs]);
      const isValid = isValidSubForm([...selects, ...inputs]);
      validateNextStepButton(subForm, isValid);
    });
  });
};

const initStepsControlButton = () => {
  const buttons = document.querySelectorAll('[data-steps-control-button]');
  const subForms = document.querySelectorAll('[data-sub-form]');

  subForms.forEach((subForm) => handleSubFormValidation(subForm));

  buttons.forEach((button) => {
    button.addEventListener('click', handleChangeStep);
  });
};

export default function initStepsForms() {
  initStepsControlButton();
}
