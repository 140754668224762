export default function initChangeTemplateDownloadLink() {
  const templateDownloadButton = document.getElementById('templateDownloadLink');
  // Use jQuery due to Select2 not firing native events
  // https://github.com/select2/select2/issues/1908
  const $templateSelect = $('#bulk_upload_activity_template_id');

  if (templateDownloadButton) {
    $templateSelect.on('change', (event) => {
      const templateSelectValue = $templateSelect.val();
      const templateDownloadButtonUrl = new URL(templateDownloadButton.href);
      const templateDownloadButtonSearchParams = new URLSearchParams(templateDownloadButtonUrl);
      templateDownloadButtonSearchParams.set('activity_template', templateSelectValue);
      templateDownloadButtonUrl.search = templateDownloadButtonSearchParams.toString();
      templateDownloadButton.href = templateDownloadButtonUrl.href;
    });
  }
}
