import { Controller } from "stimulus";

export default class extends Controller {
  updateScope(event) {
    event.preventDefault();
    setColor(event.target);
    const scope = event.currentTarget.dataset.scope;
    const button = document.getElementById("submit_delete");
    button.href = url(button, scope);
    setWarning(scope);
    show();
  }

  cancel(event) {
    hide();
  }
}

function url(button, scope){
  const currentHref = button.href;
  const url = new URL(currentHref);
  url.searchParams.set('scope_destroy', scope);

  return url.toString();
}

function setWarning(scope) {
  const strategy = {
    all: 'Se borrarán todas las actividades de este subtipo',
    academic: 'Se borrarán todas las actividades pertenecientes a los academicos de este subtipo',
    academic_history: 'Se borrarán todas las actividades pertenecientes a historial academico de este subtipo',
    data_manager: 'Se borrarán todas las actividades pertenecientes a gestor de datos de este subtipo',
    automatic: 'Se borrarán todas las actividades que no se les hayan dado un cargo'
  };
  const warning = document.getElementById('destroy_warning');
  warning.innerHTML = strategy[scope];
}

function show(){
  const button = document.getElementById("submit_delete")
  const warning = document.getElementById("destroy_warning")
  button.classList.remove("d-none");
  warning.classList.add("d-block");
}

function hide(){
  const button = document.getElementById("submit_delete");
  const warning = document.getElementById("destroy_warning");
  button.classList.add("d-none");
  warning.classList.remove("d-block");
}

function setColor(element){
  let last = element.parentNode.getElementsByClassName("btn-secondary")[0];
  if (last) last.classList.remove("btn-secondary");
  element.classList.add("btn-secondary");
}
