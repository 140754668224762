let visualizationTypes = {};

const handleChangePeriodSelect = (e, inputHidden, inputYear) => {
  const { target: { value } } = e;
  const year = inputYear.value;
  if (year !== '') {
    inputHidden.value = `${year}-${value}`.replace('X-', '');
  }
};

const handleChangeInputYear = (e, inputHidden, periodSelect) => {
  const { target: { value } } = e;
  if (!value) {
    inputHidden.value = '';
  } else if (periodSelect && periodSelect.value !== '') {
    const period = periodSelect.value;
    inputHidden.value = `${value}-${period}`.replace('X-', '');
  } else if (periodSelect === undefined) {
    inputHidden.value = `${value}-01-01`;
  }
  inputHidden.dispatchEvent(new Event('change'));
};

const getVisualizationTypesFromInputHidden = () => {
  const input = document.getElementById('visualization-types-input');

  if (input) {
    const { value } = input;
    visualizationTypes = JSON.parse(value);
  }
};

export default function initCustomVisualizationDateInputs() {
  getVisualizationTypesFromInputHidden();

  const customVisualizationDateInputs = document.querySelectorAll('.custom-visualization-date');
  if (customVisualizationDateInputs) {
    customVisualizationDateInputs.forEach((input) => {
      const { dataset: { visualizationType } } = input;

      let periodSelect = null;

      const inputs = input.getElementsByTagName('input');

      const inputHidden = inputs[1];
      const inputYear = inputs[0];

      inputYear.addEventListener('change', (e) => handleChangeInputYear(e, inputHidden, periodSelect?.[0]));

      if (visualizationType === visualizationTypes.periodo) {
        periodSelect = input.getElementsByTagName('select');
        $(periodSelect[0]).on('change', (e) => handleChangePeriodSelect(e, inputHidden, inputYear));
      }
    });
  }
}
