import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["content", "toggle", "hiddenTextMeasure"]
  static values = { id: String }

  connect() {
    this.show = false
    this.checkIfTruncated()
    this.updateText()
  }

  toggle(event) {
    event.preventDefault()
    this.show = !this.show
    this.updateText()
  }

  updateText() {
    if (this.show) {
      this.contentTarget.style.webkitLineClamp = 'unset'
      this.toggleTarget.textContent = "Ver menos"
    } else {
      this.contentTarget.style.webkitLineClamp = '3'
      this.toggleTarget.textContent = 'Ver más'
      this.checkIfTruncated()
    }
  }

  checkIfTruncated() {
    const hiddenTextMeasure = this.hiddenTextMeasureTarget
    const contentHeight = hiddenTextMeasure.scrollHeight
    const lineHeight = parseInt(window.getComputedStyle(hiddenTextMeasure).lineHeight)
    const maxLines = 3
    const maxHeight = lineHeight * maxLines

    if (contentHeight <= maxHeight) {
      this.toggleTarget.remove()
    }
  }
}