import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        var $select2 =  $(this.element)
        configureSelect2($select2);
      

        // Se necesita un temporizador para que el select2 ya haya cargado cuando se agregue el evento
        setTimeout(() => {
            var inputElement = this.element.parentNode.querySelectorAll(".select2-search__field")[0]
            inputElement.addEventListener("input",  function(event) {
                setRut(event.target)
            });
        }, "1000");
    }
}
const getOptions = (select) => {
    const options = select[0].querySelectorAll('option');
    return Array.from(options).map((opt) => opt.label);
  };

  const userExists = (userSearched, options) => {
    let userFounded = false;
    options.forEach((user) => {
      const userSplited = user.split(' - ');
      if (userSplited[1] === userSearched) {
        userFounded = true;
      }
    });
  
    return userFounded;
  };

const configureSelect2 = (select) => {
    const { nextElementSibling } = select[0];
    if (nextElementSibling && nextElementSibling.classList.contains('select2')) {
      nextElementSibling.remove();
      select[0].classList.remove('select2-hidden-accessible');
      select[0].removeAttribute('data-select2-id');
    }
    
    const $this = $(select[0]);
  
    const options = getOptions($this);
  
    $this.select2({
      width: '100%',
            matcher: customMatcher,
            tags: true,
            createTag(params) {
            const term = params.term.trim();

            if (term === '') {
                return null;
            }

            if (userExists(term, options)) {
                return null;
            }

            return {
                id: `id-${term}`,
                text: term
            };
            },
       
      language: 'es',
      width: '100%',
      dropdownParent: $this.parent(),
      templateSelection: (tag, container) => {
        const $option = $(`select option[value="${tag.id}"]`);
        if ($option.attr('locked')) {
          $(container).addClass('locked-tag');
          tag.locked = true;
        }
        return tag.text;
      }
    }).on('select2:unselecting', (e) => {
      // Cancel select2 default opening event when the clear selection button is clicked
      $this.on('select2:opening', (openingEvent) => {
        openingEvent.preventDefault();
        $this.off('select2:opening');
      });
  
      if ($(e.params.args.data.element).attr('locked')) {
        e.preventDefault();
      }
    });
  };

function setRut(element){
    var value = element.value
    // Si el primer caracter no es un numero se asume que no es un rut
    if (!is_numeric(value[0])) return;
    value = cleanRut(value)
    element.value = formatRut(value)
}

function is_numeric(str){
    return /^\d+$/.test(str);
}

function cleanRut(inputString) {
    var cleanedString = inputString.replace(/[^0-9kK]/g, '');
    cleanedString = cleanedString.replace(/[kK](?!$)/g, '');
    return cleanedString;
}

function formatRut(rut){
    try {
        if (rut.length <= 1) return rut;

        var lastChar =  rut[rut.length - 1];
        var cutRut = rut.slice(0,-1)

        var formatted = cutRut.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return formatted+'-'+lastChar
    } catch (error) {
        return rut;
    }
}

function customMatcher(params, data) {
    // Modify the matcher function to search against the raw value
    if ($.trim(params.term) === '') {
      return data; // Return all options if no search term is entered
    }
  
    // Search logic for terms without "." or "-"
    var termWithoutSymbols = params.term.replace(/[.-]/g, '');
    var dataWithoutSymbols = data.text.replace(/[.-]/g, '');
  
    if (dataWithoutSymbols.indexOf(termWithoutSymbols) > -1) {
      return data; // Return matching options
    }
    return null; // Return no matches
}
