import {
  addOrRemoveInvalidClassToInput,
  changeErrorMessageOfInput,
  INVALID_CLASS_ACTIONS,
  MESSAGE_ERRORS
} from './form-activity-template-visualization-card-form-errors';

const getCardName = (otherInput) => {
  const cardForm = otherInput.parentElement.closest('[id]');
  const header = cardForm.previousElementSibling;
  const titleElement = header.querySelector('h2');
  const cardName = titleElement.outerText.trim();
  return cardName;
};

const handleChangeInputOrderValue = (e, inputs) => {
  const { target: { id, value }, target } = e;
  let inputWithOrderRepeated;

  inputs.forEach((otherInput) => {
    const { id: otherId, value: otherValue } = otherInput;

    const valueIsNotEmpty = !!value;
    const isOtherInput = id !== otherId;
    const isSameValue = value === otherValue;
    const isNotInitialStateCard = !otherId.includes('-initial-state');
    const isNotNewCard = !otherId.includes('-new-card');

    if (isOtherInput && isSameValue && isNotInitialStateCard && valueIsNotEmpty && isNotNewCard) {
      inputWithOrderRepeated = otherInput;
    }
  });

  const cardName = inputWithOrderRepeated ? getCardName(inputWithOrderRepeated) : '';
  const action = inputWithOrderRepeated ? INVALID_CLASS_ACTIONS.ADD : INVALID_CLASS_ACTIONS.REMOVE;
  const message = inputWithOrderRepeated ? MESSAGE_ERRORS.ORDER_REPEATED(cardName) : '';

  addOrRemoveInvalidClassToInput(target, action);
  changeErrorMessageOfInput(target, message);
};

const initOrderValueInput = (cardContext) => {
  let inputs = null;

  if (cardContext) {
    inputs = cardContext.querySelectorAll('[data-order-value-input]');
  } else {
    inputs = document.querySelectorAll('[data-order-value-input]');
  }

  inputs.forEach((input) => {
    input.addEventListener('input', (e) => handleChangeInputOrderValue(e, inputs));
  });
};

export default initOrderValueInput;
