import initActivityTemplateVisualizationFormDragAndDrop from './form-activity-template-visualization-drag-and-drop';
import {
  initAddCardButton,
  initAddFieldButtons,
  initCancelButtonNewCard,
  initDeleteFieldButtons,
  initCancelButtons,
  initEditButtons,
  initDeleteCardButtons,
  initDeleteCardButtonAlert
} from './form-activity-template-visualization-cards-buttons';
import {
  initExternalDataUrlInput,
  initFieldLabelInput,
  initNamespaceCheckbox,
  initFieldReferenceCheckbox,
  initializeKindSelects,
  initFieldInput
} from './form-activity-template-visualization-card-inputs';
import initOrderValueInput from './form-activity-template-visualization-card-order-validation';
import { initWrapperAlerts } from '../fixed-alerts';
import { initActivityTemplateVisualizationFormErrors } from './form-activity-template-visualization-card-form-errors';

let fieldOptions = [];

const initActivityFieldButtonsAndInputs = (cardContext) => {
  initDeleteFieldButtons(cardContext);
  initActivityTemplateVisualizationFormDragAndDrop(cardContext);
  initFieldInput(fieldOptions, cardContext);
  initializeKindSelects(fieldOptions, cardContext);
  initFieldReferenceCheckbox(cardContext);
  initNamespaceCheckbox(cardContext);
  initFieldLabelInput(cardContext);
  initExternalDataUrlInput(cardContext);
  initOrderValueInput(cardContext);
};

const initVisualizationCardButtons = (cardContext) => {
  // eslint-disable-next-line no-use-before-define
  initCancelButtons(initButtonsAndInputs, cardContext);
  initAddFieldButtons(initActivityFieldButtonsAndInputs, cardContext);
};

const initButtonsAndInputs = (cardContext) => {
  initActivityFieldButtonsAndInputs(cardContext);
  initVisualizationCardButtons(cardContext);
};

const initNonResettableButtons = () => {
  initCancelButtonNewCard(initActivityFieldButtonsAndInputs);
  initAddCardButton();
  initEditButtons();
  initDeleteCardButtons();
  initDeleteCardButtonAlert();
};

export default function initActivityTemplateVisualizationFormEvents() {
  initWrapperAlerts();

  const fieldOptionsElement = document.getElementById('field-options');
  if (fieldOptionsElement) {
    fieldOptions = JSON.parse(fieldOptionsElement.value);
  }

  initButtonsAndInputs();
  initNonResettableButtons();
  initActivityTemplateVisualizationFormErrors();
}
