import Sortable from 'sortablejs';
import reindex from './namespaces/reindex-helper';

function createSortables() {
  const activityKindList = document.getElementById('activityKindList');
  if (!activityKindList) return;

  Sortable.create(activityKindList, {
    group: 'activityKindList',
    handle: '.drag-indicator',
    onUpdate: (e) => reindex(e.item)
  });

  for (const activityGroupList of activityKindList.getElementsByClassName('activityGroupList')) {
    Sortable.create(activityGroupList, {
      group: `activityGroupList-${activityGroupList.id}`,
      handle: '.drag-indicator',
      onUpdate: (e) => reindex(e.item)
    });

    for (const activityTemplateList of activityGroupList.getElementsByClassName('activityTemplateList')) {
      Sortable.create(activityTemplateList, {
        group: `activityTemplateList-${activityTemplateList.id}`,
        handle: '.drag-indicator',
        onUpdate: (e) => reindex(e.item)
      });
    }
  }
}

function getVisibilityInputFromSpan(span) {
  return $(span).parentsUntil('.inputs-container')
    .last()
    .parent()
    .find('.visibility-input');
}

function removeInvisibleState(visibilityButton) {
  visibilityButton.classList.remove('active');
  $(visibilityButton).closest('.visibility-container').removeClass('disabled');

  const visibilityButtonSpan = $(visibilityButton).children('span');
  visibilityButtonSpan.removeClass('text-primary');
  visibilityButtonSpan.text('visibility');

  const visibilityInput = getVisibilityInputFromSpan(visibilityButtonSpan);
  visibilityInput.val('false');
}

function bindVisibilityButtons() {
  for (const button of document.getElementsByClassName('visibility-toggle')) {
    button.addEventListener('click', (e) => {
      const input = getVisibilityInputFromSpan(e.target);
      const oldValue = input.val();
      const newValue = (oldValue === 'false');
      input.val(newValue);

      const span = e.target.firstChild;
      const draggableAncestor = $(span)
        .closest('.visibility-container');
      draggableAncestor.toggleClass('disabled');
      span.textContent = (newValue ? 'visibility_off' : 'visibility');
      $(button).toggleClass('active');
      $(e.target).toggleClass('text-primary');

      for (const visibilityButton of draggableAncestor.find('.visibility-toggle.active')) {
        if (!visibilityButton.isSameNode(button)) {
          removeInvisibleState(visibilityButton);
        }
      }
    });
  }
}

export default function initCustomSummary() {
  createSortables();
  bindVisibilityButtons();
}
