import { Controller } from "stimulus";
import { initCloseButton, autoCloseAlerts } from '../behaviours/alerts';
import { DateTime } from 'luxon';

const getDateFormat = (date) => new Date(date.getTime() + date.getTimezoneOffset() * 60000);
const activityWasDiscarded = (discardedPeriod) => discardedPeriod[1];
const isValidDate = (value) => DateTime.fromSQL(value).isValid;
const replaceHyphens = (string) => string.split('-').join('/');

const dateFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
};

export default class extends Controller {
    static values = {
        instance: Number
    }

    connect() {
      this.firstCall = true;
    }

    openHistory(event){
        if (!this.firstCall) return;
        this.firstCall = false;
        const url = `/api/activity_instances/${this.instanceValue}/feed`;
        fetchAndReplaceHistory(url, event);
    }
}

function fetchAndReplaceHistory(url, event) {
  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      const rows = processResponse(data);
      insertRows(data, rows);
    })
    .catch(error => {
      console.error('Fetch failed:', error);
      handleFail();
    });
}

function processResponse(response){
  const activityChanges = response.data.activity_instance_feed;
  const rows = [];

  activityChanges.forEach(function(change) {
    let message = createMessage(change);
    rows.push(message);
  });

  return rows
}

function createMessage(change){
  const title = getChangeTitle(change)
  const detail = getChangeDetail(change)

  return `
  <li class="swap-text-box">
    <td>
      <p class="p-color--gray p-size--lg text-weight--bold">${title}</p>
      <p class="p-color--gray">${detail}</p>
    </td>
  </li>
  <hr />
`;
}

function getChangeTitle(change) {
  const strategy = {
    create: title_create,
    update: title_update,
    bulk_upload_create: title_bulk_upload_create,
    activity_upload_create: title_bulk_upload_create,
    activity_upload_update: title_bulk_upload_update,
    admin_create: title_admin_create,
    admin_update: title_bulk_upload_update
  }

  const msg = strategy[change.event](change)

  return `
    ${change.academicUnits ? `<span class="d-block">${change.academicUnits.join(', ')}</span>` : ''}
    ${msg} - ${hoverTime(change.created_at)}
  `;
}

function title_create({owner, actor, delegate}){
  if (delegate) {
    return `Ingresado a nombre de ${owner} por delegado ${actor.full_name}`;
  } else {
    return `Ingresado por ${actor.full_name} como ${actor.role}`;
  }
}

function title_update({owner, actor, delegate}){
  if (delegate) {
    return `Editado a nombre de ${owner} por delegado ${actor.full_name}`;
  } else {
    return `Editado por ${actor.full_name} como ${actor.role}`;
  }
}

function title_bulk_upload_create({actor, academic_units}){
  const isPlural = academic_units.length > 1;
  return `
    Ingresado a nombre de la${isPlural ? 's' : ''} 
    unidad${isPlural ? 'es' : ''} ${joinWithAnd(academic_units)} por ${actor.role} ${actor.full_name}
  `;
}

function title_bulk_upload_update({actor}){
  return `Editado por ${actor.role} ${actor.full_name}`;
}

function title_admin_create({owner, academic_units, actor}){
  const isPlural = academic_units.length > 1;

  if(academic_units.length > 0) {
    return `
    Ingresado a nombre de la${isPlural ? 's' : ''} 
    unidad${isPlural ? 'es' : ''} ${joinWithAnd(academic_units)} por ${actor.role} ${actor.full_name}
  `;
  } else {
    return `
    Ingresado a nombre de ${owner} por ${actor.role} ${actor.full_name}
  `;
  }
}

function title_admin_update({actor}){
  return `Editado por administrador/operador ${actor.full_name}`;
}
function hoverTime(creationDate){
  const date = new Date(creationDate);
  const dateTimeText = DateTime.fromJSDate(date).setLocale('es').toRelative();
  const dateTimeHoverText = replaceHyphens(date.toLocaleDateString('es-CL', dateFormatOptions));

  return `
    <span class="swap-text-box-normal">${dateTimeText}</span>
    <span class="swap-text-box-hover">${dateTimeHoverText}</span>
  `;
}

function getChangeDetail(change){
  let detail = '';
  const valuesChanges = change.field_values_feed;

  detail = detail.concat(disctedDetail(change))

  valuesChanges.forEach(function(valueChange) {
    if (valueChange.label === 'tags') {
      detail = tag_detail(detail, valueChange);
    } else if ('value' in valueChange.changes) {
      const strategy = {
        'create': detail_create,
        'update': detail_update,
        'admin_update': detail_update,
        'destroy': detail_destroy,
        'activity_upload_update': detail_update
      }

      const valuesDiff = valueChange.changes.value;
      detail = detail.concat(strategy[valueChange.event]({
        "valueChange": valueChange,
        "firstValue": getFormatedValue(valuesDiff[0]),
        "secondValue": getFormatedValue(valuesDiff[1])}
        ))
    }
  })

  return detail
}

// TODO: refactorizar este metodo de magnet
function tag_detail(detail, valueChange){
  const oldTags = valueChange.changes.value[0];
  const newTags = valueChange.changes.value[1];

  const tagsAdded = newTags.filter((tag) => !oldTags.find((oldTag) => oldTag === tag));
  const tagsDeleted = oldTags.filter((tag) => !newTags.find((newTag) => newTag === tag));

  if (tagsAdded.length) {
    const isPlural = tagsAdded.length > 1;
    detail = `
      Agregó la${isPlural ? 's' : ''} etiqueta${isPlural ? 's' : ''}
      ${tagsAdded.join(', ')}
    `;
  }

  if (tagsDeleted.length) {
    const isPlural = tagsDeleted.length > 1;
    detail = `${detail ? `${detail}<br/>` : ''}
    Eliminó la${isPlural ? 's' : ''} etiqueta${isPlural ? 's' : ''}
    ${tagsDeleted.join(', ')}`;
  }

  return detail
}

function disctedDetail(change){
  const isDiscardedUpdate = Object.prototype.hasOwnProperty.call(change, 'discarded_at');
  if (isDiscardedUpdate) {
    if (activityWasDiscarded(change.changes.discarded_at)) {
      return detail.concat('Ocultó la actividad');
    } else {
      return detail.concat('Mostró la actividad');
    }
  }
  return ''
}

function detail_create({valueChange, secondValue}){
  return `
    Creó el campo ${valueChange.label}
    con el valor ${secondValue}.<br/>
  `;
}

function detail_update({valueChange, firstValue, secondValue}){
  return `
    Modificó el valor del campo ${valueChange.label}
    de ${firstValue} a ${secondValue}.<br/>
  `;
}

function detail_destroy({valueChange, firstValue}){
  return `
    Eliminó el valor ${firstValue}
    del campo ${valueChange.label}.<br/>
  `;
}

function insertRows(response, rows){
  const historyContent = document.getElementById('activityHistory');
  if (historyContent) {
    if (rows.length > 0) {
      historyContent.innerHTML = rows.join('');
      document.querySelector('#activityHistoryPagination').innerHTML = response.pagination;
      addPaginationEventListeners();
    } else {
      historyContent.innerHTML = no_changes_card;
    }
  }
}

function handleFail(){
  const historyContent = document.querySelector('#activityHistory');
  historyContent.innerHTML = fail_card;
  autoCloseAlerts();
  initCloseButton();
  // Remove paginator
  document.querySelector('#activityHistoryPagination').innerHTML = '';
}

function getFormatedValue(value) {
  if (validateValue(value)) {
    return isValidDate(value)
      ? replaceHyphens(getDateFormat(new Date(value)).toLocaleDateString('es-CL'))
      : value;
  }
  return 'vacío';
}

function addPaginationEventListeners() {
  const paginationButtons = document.querySelectorAll('#activityHistoryPagination button');

  paginationButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
      fetchAndReplaceHistory(button.dataset.apiLink, event);
    });
  });
}

function joinWithAnd(arr) {
  if (arr.length === 0) return "";
  if (arr.length === 1) return arr[0];

  const lastElement = arr.pop();
  const joinedString = `${arr.join(", ") } y ${lastElement}`;
  arr.push(lastElement);
  return joinedString;
}

const no_changes_card = `
  <li class="list-group-item">
      Sin cambios registrados.
  </li>
`

const fail_card =`
  <div class="uc-alert error mb-12 3">
      <div class="uc-alert_content">
      <i class="uc-icon icon-size--sm">cancel</i>
      <span id="js-alert-msg" class="p p-size--sm bold ml-8">Error cargando el historial</span>
      </div>
      <div class="uc-alert_close d-flex align-items-center">
      <button aria-label="Cerrar" class="uc-alert--close uc-btn btn-inline">
          <i class="uc-icon">close</i>
      </button>
      </div>
  </div>
`
function validateValue(value) {
  // Verificar si el valor es nulo o undefined
  if (value === null || value === undefined) {
    return false;
  }

  // Verificar si el valor es una cadena y no está vacía
  if (typeof value === 'string' && value.trim() === '') {
    return false;
  }

  // Verificar si el valor es un array y no está vacío
  if (Array.isArray(value) && value.length === 0) {
    return false;
  }

  // Si no se cumple ninguna de las condiciones anteriores, el valor es válido
  return true;
}
