function hideAlerts(alertType) {
  let alerts;
  switch (alertType) {
    case 'success':
      alerts = document.querySelectorAll('.alert-success');
      break;
    default:
      break;
  }

  if (alerts) {
    alerts.forEach((alert) => {
      alert.parentNode.removeChild(alert);
    });
  }
}

function handleActivityForm(button, action) {
  const card = button.dataset.faCardRef;
  const form = button.dataset.faFormRef;
  //const cardElement = document.getElementById(`${card}`);
  const cardElements = document.querySelectorAll(`#${card}`); 
  const formElement = document.getElementById(`${form}`);

  // Hide Card and show form
  if (cardElements && formElement) {
    switch (action) {
      case 'edit':
        hideAlerts('success');
        for (const cardElement of cardElements) {
          cardElement.classList.replace('d-block', 'd-none');
        }
        formElement.classList.replace('d-none', 'd-block');
        break;
      case 'cancel':
        formElement.classList.replace('d-block', 'd-none');
        formElement.querySelector('form').reset();
        for (const cardElement of cardElements) {
          cardElement.classList.replace('d-none', 'd-block');
        }
        
        break;
      default:
        break;
    }
  }
}

export default function initButtonsForms() {
  // Add listener to edit buttons
  const editButtons = document.querySelectorAll("[data-fa-form-buttom='edit']");
  if (editButtons) {
    editButtons.forEach((editButton) => {
      editButton.addEventListener('click', (event) => {
        event.stopPropagation();
        handleActivityForm(event.currentTarget, 'edit');
      });
    });
  }

  // Add listener to the cancel button
  const cancelButtons = document.querySelectorAll("[data-fa-form-buttom='cancel']");
  if (cancelButtons) {
    cancelButtons.forEach((cancelButton) => {
      cancelButton.addEventListener('click', (event) => {
        event.stopPropagation();
        handleActivityForm(event.currentTarget, 'cancel');
      });
    });
  }
}
