function getElementLinesAmount(element) {
  var lineHeightRaw = window.getComputedStyle(element, null).getPropertyValue('line-height');
  var lineHeight = parseFloat(lineHeightRaw);

  var height = element.clientHeight;
  if (height === 0) {
    // Clonar el elemento
    //const hiddentext = clone.querySelector(`#${element.id}`);
    const clone = cloneIntoCardBody(element.parentElement)

  const paragraphsInElement = element.parentElement.querySelectorAll('p');
  paragraphsInElement.forEach(p => {
    if (p.innerHTML.trim() === '' && !p.attributes.length) {
      p.parentElement.removeChild(p);
    }
  });

    const linksInClone = clone.querySelectorAll('a');
    linksInClone.forEach(link => link.parentElement.removeChild(link));

    const container = document.querySelector('.container.pt-20');
    if (container) {
      container.appendChild(clone);
    } else {
      // Si no se encuentra el contenedor específico, añadir al body por defecto
      document.body.appendChild(clone);
    }

    // Medir la altura del clon
    const elementInClone = clone.querySelector(`#${element.id}`);
  
    height = elementInClone.parentElement.clientHeight;
    container.removeChild(clone);
  }

  return Math.ceil(height / lineHeight);
}

function cloneIntoCardBody(element) {
  // Crear la estructura específica
  const ucCardBody = document.createElement('div');
  ucCardBody.classList.add('uc-card_body');

  const ucCard = document.createElement('div');
  ucCard.classList.add('uc-card', 'p-24');
  ucCard.id = 'kind_id_3_card';

  const collapse1 = document.createElement('div');
  collapse1.classList.add('me-m-1');
  collapse1.id = 'kind_collapse_3';

  const mt16_1 = document.createElement('div');
  mt16_1.classList.add('mt-16', 'd-flex', 'flex-column', 'gap-4');

  const ucCard2 = document.createElement('div');
  ucCard2.classList.add('uc-card', 'p-24');
  ucCard2.id = 'group_id_13_card';

  const collapse2 = document.createElement('div');
  collapse2.id = 'group_collapse_13';

  const mt16_2 = document.createElement('div');
  mt16_2.classList.add('mt-16', 'd-flex', 'flex-column', 'gap-4');

  const ucCard3 = document.createElement('div');
  ucCard3.classList.add('uc-card', 'p-24');
  ucCard3.id = 'group_id_23_card';

  const collapse3 = document.createElement('div');
  collapse3.id = 'group_collapse_23';

  const mt16_3 = document.createElement('div');
  mt16_3.classList.add('text-break', 'uc-btn', 'btn-featured');

  const outerSpan = document.createElement('span');
  outerSpan.classList.add('p-color--gray', 'mt-8', 'mb-0');
  outerSpan.style.textAlign = 'justify';

  const innerSpan = document.createElement('span');

  const innerDiv = document.createElement('div');
  innerDiv.classList.add('mb-36');

  // Clonar el elemento especificado
  const clonedElement = element.cloneNode(true);

  // Añadir el clon dentro de la estructura creada
  innerDiv.appendChild(clonedElement);
  innerSpan.appendChild(innerDiv);
  outerSpan.appendChild(innerSpan);
  mt16_3.appendChild(outerSpan);

  // Construir la estructura final
  collapse3.appendChild(mt16_3);
  ucCard3.appendChild(collapse3);
  mt16_2.appendChild(ucCard3);
  collapse2.appendChild(mt16_2);
  ucCard2.appendChild(collapse2);
  mt16_1.appendChild(ucCard2);
  collapse1.appendChild(mt16_1);
  ucCard.appendChild(collapse1);
  ucCardBody.appendChild(ucCard);

  return ucCardBody;
}

function initShowMoreToggler(toggler) {
  const hiddenTaxonomyInput = document.querySelector(`#taxnonomy_hidden_input`);
  const target = document.getElementById(toggler.dataset.targetId);
  if (!target) return;

  const { showMinLines, alwaysShowToggle } = target.dataset;

  if (!showMinLines) {
    console.error('Error: The attribute data-show-min-lines is missing in target element.');
  }
  if (Number.isNaN(showMinLines) || Number(showMinLines) < 1 || Number(showMinLines) > 4) {
    console.error('Error: The value of the attribute data-show-min-lines should be between 1 and 4.');
  }
  const targetLines = getElementLinesAmount(target);

  // Hide the toggler if the target element has the minimum amount of lines or less
  // or if data-always-show-toggle atribute isn't true
  
  if (Number(showMinLines) >= targetLines && alwaysShowToggle !== 'true') {
    toggler.classList.add('d-none');
  }

  const targetClampLinesClass = `clamp-lines-${showMinLines}`;

  // Show target content initially if data-show is true, otherwise hide it
  if (target.dataset.show === 'true') {
    target.classList.remove(targetClampLinesClass);
  } else {
    target.classList.add(targetClampLinesClass);
  }


//ajustar aqui para que lo haga con el parent si es que es de taxonomia
if (hiddenTaxonomyInput!=null){
  if (target.dataset.show === 'true') {
    target.parentElementclassList.remove(targetClampLinesClass);
  } else {
    target.parentElement.classList.add(targetClampLinesClass);
  }
}
  // Show or hide target content when toggler is clicked
  toggler.addEventListener('click', (e) => {
    e.preventDefault();

    if (target.dataset.show === 'true') {
      toggler.textContent = 'Ver más';
      target.classList.add(targetClampLinesClass);
      target.dataset.show = 'false';
    } else {
      toggler.textContent = 'Ver menos';
      target.classList.remove(targetClampLinesClass);
      target.dataset.show = 'true';
    }
   
   if (hiddenTaxonomyInput!=null){
    if (target.parentElement.dataset.show === 'true') {
      toggler.textContent = 'Ver más';
      target.parentElement.classList.add(targetClampLinesClass);
      target.parentElement.dataset.show = 'false';
    } else {
      toggler.textContent = 'Ver menos';
      target.parentElement.classList.remove(targetClampLinesClass);
      target.parentElement.dataset.show = 'true';
    }
  }
  });
}

export default function initShowMoreTogglers() {
  const showMoreTogglers = document.querySelectorAll('.show-more-toggler');
  showMoreTogglers.forEach(initShowMoreToggler);
}
