function checkFileInputRestrictions(input) {
  const fileAcceptedFormats = input.getAttribute('accept');
  const fileMaximumSize = input.dataset.sizeLimit;
  const result = {
    isValid: true,
    errorMessage: ''
  };

  for (const file of input.files) {
    if (fileAcceptedFormats) {
      const fileExtension = `.${file.name.split('.').pop()}`;
      if (!fileAcceptedFormats.includes(fileExtension)) {
        result.isValid = false;
        result.errorMessage = 'Archivo no cargado - El formato de los archivos cargados es incorrecto.';
      }
    }

    if (fileMaximumSize && file.size > fileMaximumSize) {
      result.isValid = false;
      result.errorMessage = 'Archivo no cargado - Uno de los archivos excede el peso máximo';
    }
  }

  return result;
}

function handleFileInputChange({ target }) {
  const $inputWrapper = $(target).parent('.custom-file');
  const $inputLabel = $inputWrapper.children('.custom-file-label:not(.file-name)');
  const $inputFileMessage = $inputWrapper.siblings('.custom-file-message');
  const $fileNameLabel = $inputWrapper.children('.custom-file-label.file-name');
  const fileWasUploaded = target.files.length > 0;

  if (fileWasUploaded) {
    const firstFileName = target.files[0].name;
    const filesValidator = checkFileInputRestrictions(target);

    if (filesValidator.isValid) {
      if ($fileNameLabel.length) {
        $fileNameLabel.text(firstFileName);
      } else {
        $inputWrapper.append(`<span class="custom-file-label file-name">${firstFileName}</span>`);
      }

      $inputLabel.addClass('d-none');
      $(target).removeClass('is-invalid');
      $(target).addClass('is-valid');

      if (!$inputFileMessage.length) {
        $('<span class="custom-file-message">Archivo subido con éxito</span>').insertAfter($inputWrapper);
      } else {
        $inputFileMessage.removeClass('text-danger');
        $inputFileMessage.text('Archivo subido con éxito');
      }
    } else {
      target.value = '';
      $inputLabel.removeClass('d-none');
      $(target).removeClass('is-valid');
      $(target).addClass('is-invalid');
      $fileNameLabel.remove();
      if (!$inputFileMessage.length) {
        $(`<span class="custom-file-message text-danger">${filesValidator.errorMessage}</span>`).insertAfter($inputWrapper);
      } else {
        $inputFileMessage.addClass('text-danger');
        $inputFileMessage.text(filesValidator.errorMessage);
      }
    }
  } else {
    $fileNameLabel.remove();
    $inputLabel.removeClass('d-none');
    $(target).removeClass('is-valid', 'is-invalid');
    $inputFileMessage.remove();
  }
}

export default function initCustomFileInput() {
  for (const fileInput of document.querySelectorAll('.custom-file .custom-file-input')) {
    fileInput.addEventListener('change', handleFileInputChange);
  }
}
