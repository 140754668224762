const getPeriod = (periodsJson, currentPeriod) => {
  const periodsKeys = Object.keys(periodsJson);
  let currentPeriodFormatted;
  periodsKeys.forEach((key) => {
    if (periodsJson[key] === currentPeriod) {
      currentPeriodFormatted = key.charAt(0).toUpperCase() + key.slice(1);
    }
  });
  return currentPeriodFormatted;
};

export default function initFormatVisualizationType() {
  const datesToFormat = document.querySelectorAll('[data-format-visualization-type]');

  datesToFormat.forEach((dateElement) => {
    dateElement.innerText = '';
    const { dataset: { formatVisualizationType, datePeriods, value: date } } = dateElement;

    if (date !== '') {
      const [year, month, day] = date.split('-');
      const currentPeriod = `X-${month}-${day}`;
      const periodsJson = JSON.parse(datePeriods);

      if (formatVisualizationType === 'period') {
        dateElement.innerText = `${getPeriod(periodsJson, currentPeriod)} de ${year}`;
      } else if (formatVisualizationType === 'year') {
        dateElement.innerText = `${year}`;
      }
    }
  });
}
