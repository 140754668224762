import { initCloseButton, autoCloseAlerts } from './alerts';

const WRAPPER_ALERTS_ID = 'wrapper-alerts-id';

const initWrapperAlerts = () => {
  const wrapperAlert = document.createElement('div');
  wrapperAlert.classList.add('position-fixed', 'container');
  wrapperAlert.id = WRAPPER_ALERTS_ID;
  wrapperAlert.style.zIndex = '90';
  wrapperAlert.style.top = '1rem';
  wrapperAlert.style.right = '0';
  wrapperAlert.style.left = '0';

  const body = document.getElementsByTagName('body');
  Array.from(body)[0].appendChild(wrapperAlert);
};

const showAlert = (type, icon, msg) => {
  const wrapperAlerts = document.getElementById(WRAPPER_ALERTS_ID);
  const alert = document.createElement('div');
  alert.classList.add('bg-white');

  alert.innerHTML = `
      <div class="uc-alert ${type} mb-3">
        <div class="uc-alert_content">
          <i class="uc-icon icon-size--sm"  aria-hidden="true">${icon}</i>
          <span id="js-alert-msg" class="p p-size--sm bold ml-8">
            ${msg}
          </span>
        </div>
        <div class="uc-alert_close d-flex align-items-center">
          <button aria-label="Cerrar" class="uc-alert--close uc-btn btn-inline">
            <i class="uc-icon" aria-hidden="true">close</i>
          </button>
        </div>
      </div>
    `;

  wrapperAlerts.appendChild(alert);
  autoCloseAlerts();
  initCloseButton();
};

export {
  initWrapperAlerts,
  showAlert
};
