import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  open(event) {
    event.preventDefault();
    const modalTarget = event.currentTarget.getAttribute('data-mtarget');
    const modal = document.querySelector(`[data-modal="${modalTarget}"]`);
    if (modal) {
      modal.style.display = 'block';
    }
  }

  close(event) {
    event.preventDefault();
    const modal = event.currentTarget.closest('[data-modal]');
    if (modal) {
      modal.style.display = 'none';
    }
  }
}
